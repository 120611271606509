.rider-wrapper {
    background: #ffffff;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 97%;
    margin: 30px auto;
    .rider-wrapper-header {
        background: #ffffff;
        box-shadow: 0 2px 4px rgba(199, 199, 199, 0.5);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        align-items: center;
        label {
            font-size: 12px;
            margin: 5px;
            font-weight: 600;
            line-height: 20px;
        }
        .form-control {
            width: 85px;
            margin: 9px 1px;
            font-size: 12px;
        }
        .form-control:disabled,
        .form-control[readonly] {
            background-color: #e9ecef;
            opacity: 1;
            cursor: not-allowed;
        }
        .name-fields {
            .form-control {
                width: 100px;
            }
        }
      .status {
        .ember-power-select-trigger {
          width: 100px;
        }
      }
        i {
            color: #a7b4b9;
            margin: 7px 4px;
            vertical-align: middle;
            padding: 5px;
        }
        .btn {
            background: linear-gradient(180deg, #f9f9f9 0%, #d1d1d1 100%);
            border: 1px solid #d4d6d7;
            box-sizing: border-box;
            border-radius: 2px;
            margin: 7px 4px;
            width: 80px;
            font-style: normal;
            font-size: 14px;
            font-weight: 600;
            line-height: 10px;
            text-align: center;
            color: #303e44;
            height: 26px;
        }
        .ember-power-select-trigger {
            height: 23px;
            font-size: 12px;
            margin: 9px 0px 1px 0px;
            padding-left: 4px;
            overflow-x: unset;
            font-weight: 500;
            width: 145px;
            line-height: 1.2;
        }
        .ember-power-select-status-icon {
            top: 1px;
            right: 8px;
            border-width: 4px 4px 0 4px;
            border-color: #303e44 transparent transparent transparent;
        }
        .ember-power-select-options[role="listbox"] {
            overflow-y: hidden !important;
            max-height: none;
        }
        .ember-power-select-dropdown {
            line-height: 2.25;
        }
        .rider-detail {
            display: flex;
            justify-content: space-between;
        }
    }
    .rider-wrapper-body {
        .ember-view[aria-disabled=true] {
            background: transparent !important;
        }
             }
    }
    .first-row {
        .table-component .table-component-body {
            overflow: hidden;
            flex-basis: 160px;
        }
        h6 {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
        .img-icon {
            text-align: center;
            border: 1px solid #D4D6D7;
            background-color: #F5F5F7;
            border-radius: 5px;
            .container {
                width: 100%;
                top: 0;
                left: 0;
                .fa.fa-user {
                    font-size: 195px;
                    color: #D4D6D7;
                    z-index: 1;
                }
                .thumnailPreview {
                    position: absolute;
                    z-index: 2;
                    left: 7%;
                    margin-left: auto;
                    margin-right: auto;
                    height: 100%;
                    width: 86%;
                    top: 0;
                }
            }
            .btn {
                z-index: 3;
                background: linear-gradient(180deg, #f9f9f9 0%, #d1d1d1 100%);
                border: 1px solid #d4d6d7;
                box-sizing: border-box;
                border-radius: 2px;
                width: 70%;
                font-style: normal;
                font-size: 14px;
                font-weight: 600;
                line-height: 10px;
                text-align: center;
                color: #303e44;
                height: 26px;
            }
            .file-input {
                display: none;
            }
            .rider-photo-button {
                position: absolute;
                bottom: 2%;
                left: 15%;
                top: 85%;
            }
        }
    }
    .contact-second-row {
        .table-component .table-component-body {
            overflow: hidden;
            flex-basis: 160px;
        }
        h6 {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
    }
    .second-row {
        .column-widget {
            .column-widget-body {
                flex-basis: 194px !important;
                background: #f5f5f7;
                .ember-power-select-status-icon {
                    top: 1px;
                    right: 4px;
                    border-width: 4px 4px 0 4px;
                    border-color: #303e44 transparent transparent transparent;
                }
                h6 {
                    font-weight: 600;
                    font-size: 14px;
                    padding: 10px 0 5px 20px;
                    line-height: 20px;
                }
                .rider-contact {
                    margin: 0 0 6px 0;
                    .form-control {
                        height: 22px;
                        &::placeholder {
                            font-size: 12px;
                            color: #c1c1c1;
                        }
                    }
                    .col-form-label {
                        font-size: 12px;
                        font-weight: 600;
                        padding-top: 3px;
                        padding-bottom: 1px;
                        text-align: right;
                    }
                }
            }
        }
        .dob-email-wrapper {
            .column-widget {
                .column-widget-body {
                    flex-basis: 87px !important;
                    width: 100%;
                    .form-control {
                        height: 22px;
                        font-size: 12px;
                    }
                    .col-form-label {
                        font-size: 12px;
                        font-weight: 600;
                        padding-top: 3px;
                        padding-bottom: 1px;
                        text-align: right;
                    }
                    .form-components-datetimeflatpickr {
                        input {
                            width: 100%;
                            border: 1px solid #c1c1c1;
                            border-radius: 3px;
                        }
                    }
                    .form-components-datetimeflatpickr {
                        input:read-only {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
    .third-row {
        flex-basis: 142px !important;
        background: #f5f5f7;
        overflow: hidden;
        .notes {
            background-color: #fff;
        }
        .generic-panel .panel-title {
            font-weight: 600;
            font-size: 14px;
            padding: 10px 0 5px 10px;
            line-height: 20px;
        }
         ::placeholder {
            color: transparent;
        }
        .hint {
            font-size: 12px;
        }
        textarea {
            border: 1px solid #cdd4d6;
            border-radius: 3px;
            color: #303e44;
            font-size: 12px;
        }
    }


.add-rider-phone-detail-wrapper {
    .add-phone-detail-header {
        background: #ffffff;
        box-shadow: 0 2px 4px rgba(199, 199, 199, 0.5);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        justify-content: space-between;
        padding: 4px 20px;
        .actions {
            color: #a7b4b9;
            vertical-align: middle;
            i {
                margin-left: 12px;
                cursor: pointer;
            }
        }
        .add-phone-title {
            font-weight: 600;
            font-size: 16px;
            color: #303e44;
        }
    }
}

.add-phone-detail-body {
    .ember-power-select-trigger {
        height: 23px;
        font-size: 12px;
        padding-left: 4px;
        overflow-x: unset;
        font-weight: 500;
        width: 198px;
    }
    .ember-power-select-trigger[aria-disabled=true] {
        background: #e9ecef !important;
        opacity: 0.5;
        cursor: not-allowed;
    }
    .ember-power-select-status-icon {
        top: 1px;
        right: 8px;
        border-width: 4px 4px 0 4px;
        border-color: #303e44 transparent transparent transparent;
    }
    .col-form-label {
        font-size: 12px;
        font-weight: 600;
        padding-top: 3px;
        padding-bottom: 1px;
        text-align: right;
        padding-left: 0;
    }
}

.ember-modal-overlay.translucent.phone-number-detail-overlay {
    z-index: zindex(action-modal);
}

.ember-power-select-dropdown {
    margin-top: 1px;
}

.phone-number-detail-container {
    height: 150px;
    width: 635px;
    padding: 0;
}

.add-rider-contact-info-detail-wrapper {
    .add-contact-info-detail-header {
        background: #ffffff;
        box-shadow: 0 2px 4px rgba(199, 199, 199, 0.5);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        justify-content: space-between;
        padding: 4px 20px;
        .actions {
            color: #a7b4b9;
            vertical-align: middle;
            i {
                margin-left: 12px;
                cursor: pointer;
            }
        }
        .add-contact-info-title {
            font-weight: 600;
            font-size: 16px;
            color: #303e44;
        }
    }
}

.add-conatct-info-detail-body {
    .ember-power-select-trigger {
        height: 23px;
        font-size: 12px;
        padding-left: 4px;
        overflow-x: unset;
        font-weight: 500;
        width: 198px;
    }
    textarea {
        resize: none;
    }
    .ember-power-select-status-icon {
        top: 1px;
        right: 8px;
        border-width: 4px 4px 0 4px;
        border-color: #303e44 transparent transparent transparent;
    }
    .col-form-label {
        font-size: 12px;
        font-weight: 600;
        padding-top: 3px;
        padding-bottom: 1px;
        text-align: right;
        padding-left: 0;
    }
}

.ember-modal-overlay.translucent.contact-info-detail-overlay {
    z-index: zindex(action-modal);
}

.ember-power-select-dropdown {
    margin-top: 1px;
}

.conatct-info-detail-container {
    height: 150px;
    width: 635px;
    padding: 0;
}

.add-rider-address-detail-wrapper {
    .add-address-detail-header {
        background: #ffffff;
        box-shadow: 0 2px 4px rgba(199, 199, 199, 0.5);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        justify-content: space-between;
        padding: 4px 20px;
        .actions {
            color: #a7b4b9;
            vertical-align: middle;
            i {
                margin-left: 12px;
                cursor: pointer;
            }
        }
        .add-address-title {
            font-weight: 600;
            font-size: 16px;
            color: #303e44;
        }
    }
}

.ember-modal-overlay.translucent.address-detail-overlay {
    z-index: zindex(action-modal);
}

.address-detail-container {
    width: 90%;
    padding: 0;
}

.add-address-detail-body {
    .ember-power-select-trigger {
        height: 23px;
        font-size: 12px;
        padding-left: 4px;
        overflow-x: unset;
        font-weight: 500;
    }
    .ember-power-select-status-icon {
        top: 1px;
        right: 8px;
        border-width: 4px 4px 0 4px;
        border-color: #303e44 transparent transparent transparent;
    }
    .col-form-label {
        font-size: 12px;
        font-weight: 600;
        padding-top: 3px;
        padding-bottom: 1px;
        text-align: right;
        padding-left: 0;
    }
    .map-marker {
        background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
        border: 1px solid #d4d6d7;
        border-radius: 2px;
        padding: 4px 8px;
        font-size: 12px;
        color: #586368;
        vertical-align: top;
    }
    textarea {
        resize: none;
    }
    .address-map {
        .map-widget {
            height: 450px;
            border-radius: 0;
            box-shadow: none;
        }
    }
}

//search model widget
.rider-detail-container {
    overflow-y: auto;
    width: 75%;
}

.ember-modal-overlay.translucent.rider-detail-overlay {
    z-index: 9001;
    overflow-y: auto !important;
    .ember-modal-dialog table {
        border: 0px solid #cdd4d6 !important;
    }
    .ember-modal-dialog {
        padding: 0;
    }
}

.rider-search-wrapper {
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    .column-widget .column-widget-body {
        overflow: hidden;
        .table-container {
            .table-component {
                border: none;
            }
        }
    }
    .rider-details-wrapper {
        .sp-header {
            -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
            -moz-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
        }
        .sp-heading {
            padding: 9px 9px;
            font-weight: 600 !important;
            font-size: 14px !important;
            background-color: #f6f6f6;
            border-radius: 7px 7px 0 0 !important;
            line-height: 20px !important;
            .x-button {
                float: right;
                color: rgb(59, 57, 57);
            }
        }
    }
    .modal-wrapper-header {
        background: #ffffff;
        display: flex;
        align-items: center;
        border-radius: 8px;
        .col-form-label {
            font-size: 12px;
            font-weight: 600;
            padding-top: 3px;
            padding-bottom: 1px;
            text-align: right;
            margin: 0 6px;
        }
        .name-fields {
            .form-control {
                width: 85px;
                height: 22px;
                font-size: 12px;
                color: #c1c1c1;
            }
            .form-control:disabled,
            .form-control[readonly] {
                background-color: #e9ecef !important;
                opacity: 1;
            }
            .flatpickr-input {
                width: 85px;
                border: none;
                height: 20px;
            }
            .flatpickr-input:disabled,
            .form-control[readonly] {
                background-color: #e9ecef !important;
                opacity: 1;
            }
        }
        .margin {
            margin: 8px !important;
        }
        .justify-content {
            justify-content: space-between;
        }
        .btn {
            background: linear-gradient(180deg, #f9f9f9 0%, #d1d1d1 100%);
            border: 1px solid #d4d6d7;
            box-sizing: border-box;
            border-radius: 2px;
            margin: 7px 4px;
            width: 84px;
            font-style: normal;
            font-size: 14px;
            font-weight: 600;
            line-height: 10px;
            text-align: center;
            color: #303e44;
            height: 26px;
        }
    }
    .rider-table {
        .table-component-body {
            height: calc(100% - 35px);
        }
    }
}

//rider travel needs
.rider-travel-need-table {
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    .column-widget .column-widget-body {
        overflow: hidden;
        .table-container {
            .table-component {
                border: none;
                .table-component-header .table-component-controls .table-component-button {
                    opacity: 0;
                }
            }
        }
    }
}

.rider-travel-need-form {
    .column-widget {
        .card {
            border: none;
        }
        .column-widget-body {
            flex-basis: 194px !important;
            background: #f5f5f7;
            overflow: hidden;
            .ember-power-select-status-icon {
                top: 1px;
                right: 4px;
                border-width: 4px 4px 0 4px;
                border-color: #303e44 transparent transparent transparent;
            }
            h6 {
                font-weight: 600;
                font-size: 14px;
                padding: 10px 0 5px 20px;
                line-height: 20px;
            }
            .rider-travel-need {
                margin: 0 0 6px 0;
                .form-control {
                    height: 22px;
                    font-size: 12px;
                    &::placeholder {
                        font-size: 12px;
                        color: #c1c1c1;
                    }
                }
                .col-form-label {
                    font-size: 12px;
                    font-weight: 600;
                    padding-top: 3px;
                    padding-bottom: 1px;
                    text-align: right;
                }
            }
        }
    }
}

// rider suspension Ui
.rider-suspension-table {
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    .column-widget .column-widget-body {
        overflow: hidden;
        .table-container {
            .table-component {
                border: none;
                .table-component-header .table-component-controls .table-component-button {
                    opacity: 0;
                }
            }
        }
    }
}

// rider-suspension-form
.rider-suspension-form {
    .column-widget {
        .card {
            border: none;
        }
        .column-widget-body {
            flex-basis: 263px !important;
            background: #F5F5F7;
            overflow: hidden;
            h6 {
                font-weight: 600;
                font-size: 14px;
                padding: 10px 0 5px 20px;
                line-height: 20px;
            }
            .rider-suspension {
                margin: 0 0 6px 0;
                textarea {
                    resize: none;
                    border-color: #c1c1c1 !important;
                    border-radius: 4px !important;
                    height: 100px !important;
                }
                .datetimeflatpickr-wrapper .flatpickr-input {
                    input {
                        border: 1px solid #c1c1c1;
                        border-radius: 3px;
                    }
                }
                .form-control {
                    height: 22px;
                    font-size: 12px;
                }
                .col-form-label {
                    font-size: 12px;
                    font-weight: 600;
                    padding-top: 3px;
                    padding-bottom: 1px;
                    text-align: right;
                }
            }
        }
    }
}

// rider funding Ui
.rider-funding-table {
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    .column-widget .column-widget-body {
        overflow: hidden;
        .table-container {
            .table-component {
                border: none;
                .table-component-header .table-component-controls .table-component-button {
                    opacity: 0;
                }
            }
        }
    }
}

// rider-funding-form
.rider-funding-form {
    .column-widget {
        .card {
            border: none;
        }
        .column-widget-body {
            flex-basis: 263px !important;
            background: #F5F5F7;
            overflow: hidden;
            h6 {
                font-weight: 600;
                font-size: 14px;
                padding: 10px 0 5px 20px;
                line-height: 20px;
            }
            .rider-funding {
                margin: 0 0 6px 0;
                textarea {
                    resize: none;
                    border-color: #c1c1c1 !important;
                    border-radius: 4px !important;
                    height: 100px !important;
                }
                .datetimeflatpickr-wrapper .flatpickr-input {
                    input {
                        border: 1px solid #c1c1c1;
                        border-radius: 3px;
                    }
                }
                .form-control {
                    height: 22px;
                    font-size: 12px;
                }
                .col-form-label {
                    font-size: 12px;
                    font-weight: 600;
                    padding-top: 3px;
                    padding-bottom: 1px;
                    text-align: right;
                }
                .col-sm-6.pl-0 {
                    padding-right: 0;
                    border-radius: 3px;
                }
                
            }
        }
    }
}

// rider-eligibility-modal-dialog-screen
.ember-modal-overlay.translucent.rider-eligibility-info-detail-overlay {
    z-index: zindex(action-modal);
}

.ember-power-select-dropdown {
    margin-top: 1px;
}

.eligibility-info-detail-container {
    width: 635px;
    padding: 0;
}

.add-rider-eligibility-info-detail-wrapper {
    .add-rider-info-detail-header {
        background: #ffffff;
        box-shadow: 0 2px 4px rgba(199, 199, 199, 0.5);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        justify-content: space-between;
        padding: 4px 20px;
        .actions {
            color: #a7b4b9;
            vertical-align: middle;
            i {
                margin-left: 12px;
                cursor: pointer;
            }
        }
        .add-eligibility-info-title {
            font-weight: 600;
            font-size: 16px;
            color: #303e44;
        }
    }
}

// rider-eligibility UI
.rider-eligibility-wrapper {
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    .column-widget .column-widget-body {
        overflow: hidden;
        flex-basis: 375px;
        .table-container {
            .table-component {
                border: none;
                .table-component-header .table-component-controls .table-component-button {
                    opacity: inherit;
                }
            }
        }
    }
}

.serviceNeeds {
    text-align: left;
    font-family: $ui-font;
    font-size: $widget-body-font-size;
    color: black;
    line-height: normal;
    &.select {
        width: 100%;
        height: 21px;
        border: 1px solid rgba(38, 50, 56, 0.2);
        border-radius: 2px;
        background-color: #fff !important;
        color: #303E44;
        cursor: pointer;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 5px 0 5px;
        outline: none;
        &:hover {
            background-color: #F1F1F1;
        }
        .title {
            color: $title-color;
            font-weight: 500;
            flex-basis: 110px;
            flex-grow: 1;
            text-overflow: ellipsis;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
        }
        span {
            color: $title-color;
            flex-grow: 0;
            left: 88%;
            top: 0;
            bottom: 0;
            width: 0;
            margin: auto;
            position: absolute;
            display: inline-block;
            border-width: 4px 4px 0 4px;
            border-color: #303e44 transparent transparent transparent;
            i {
                color: #aaaaaa !important;
            }
        }
    }
    &.options {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        list-style: none;
        box-sizing: border-box;
        height: auto;
        border: 1px solid rgba(38, 50, 56, 0.2);
        border-radius: 2px;
        background-color: #FFFFFF;
        overflow: auto;
        max-height: 420px;
        padding: 4px 5px 8px 5px;
        .option {
            color: #303E44;
            font-family: $ui-font;
            padding: 6px 2px 0 2px;
            position: relative;
            // Checkboxes
            input[type="checkbox"] {
                position: absolute;
                left: -9999px;
                &+label {
                    position: relative;
                    display: inline-block;
                    padding: 0px 0px 0px 22px;
                    cursor: pointer;
                    width: 100%;
                    font-weight: 500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 0;
                    // Default State
                    &:before {
                        display: block;
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        margin: (-$checkbox-width/2) 0 0;
                        width: $checkbox-width;
                        height: $checkbox-width;
                        background-size: 72px auto;
                        -webkit-background-size: 72px auto;
                        -moz-background-size: 72px auto;
                        box-sizing: border-box;
                        border: 1px solid rgba(38, 50, 56, 0.2);
                        border-radius: 2px;
                        background-color: #FFFFFF;
                    }
                }
                // Checked State
                &:checked+label {
                    &:before {
                        box-sizing: border-box;
                        border: 1px solid rgba(38, 50, 56, 0.2);
                        border-radius: 2px;
                        width: $checkbox-width;
                        height: $checkbox-width;
                        background-color: #0042fd;
                        color: #FFF;
                        font-family: FontAwesome;
                        font-weight: 200;
                        content: "\f00c";
                        font-size: 9px;
                        text-align: center;
                    }
                }
                // Disabled State
                &:disabled {
                    &+label {
                        cursor: not-allowed;
                        &:before {
                            background-color: #CCC;
                        }
                    }
                    // Disabled Checked
                    &:checked+label {
                        &:before {
                            background-color: #CCC;
                        }
                    }
                }
                // hover State
                &:hover+label {
                    &:before {
                        box-sizing: border-box;
                        border: 1px solid rgba(38, 50, 56, 0.2);
                        border-radius: 2px;
                        width: $checkbox-width;
                        height: $checkbox-width;
                        background-color: #f1f1f1;
                        color: #ccc;
                        font-family: FontAwesome;
                        font-weight: normal;
                        content: "\f00c";
                        font-size: 9px;
                        text-align: center;
                    }
                }
                // hover State checked
                &:checked+label:hover {
                    &:before {
                        background-color: #1b1b1b;
                        font-weight: 600;
                        color: #FFF;
                    }
                }
            }
        }
    }
}

.rider-eligibility-service-mode {
    .column-widget {
        .card {
            border: none;
        }
        .column-widget-body {
            flex-basis: 183px !important;
            background: #f5f5f7;
            overflow: hidden;
            h6 {
                font-weight: 600;
                font-size: 14px;
                padding: 10px 0 5px 20px;
                line-height: 20px;
            }
            .col-form-label {
                font-size: 12px;
                font-weight: 600;
                padding-top: 3px;
                padding-bottom: 1px;
                text-align: right;
            }
            .form-control {
                height: 22px;
                &::placeholder {
                    font-size: 12px;
                    color: #c1c1c1;
                }
            }
        }
    }
}

.rider-eligibility-form {
    margin: auto;
    .card {
        border: 1px solid white;
        width: 500px;
    }
    .column-widget {
        .column-widget-body {
            flex-basis: 263px !important;
            overflow: hidden;
            h6 {
                font-weight: 600;
                font-size: 14px;
                padding: 10px 0 5px 20px;
                line-height: 20px;
            }
            .rider-eligibility {
                margin: 0 0 6px 0;
                textarea {
                    resize: none;
                    width: 100% !important;
                    border-color: #c1c1c1 !important;
                    border-radius: 4px !important;
                    background: #F5F5F7;
                }
              .condition-desc {
                .form-control {
                  background: #F5F5F7;
                }
              }
              .form-control[readonly] {
                background: #F5F5F7;
              }
              .ember-modal-dialog textarea:disabled {
                background-color: transparent!important;
              }
                .form-components-datetimeflatpickr {
                    input {
                        width: 100% !important;
                        border: 1px solid #c1c1c1;
                        border-radius: 3px;
                    }
                }
                .form-control {
                    height: 22px;
                    &::placeholder {
                        font-size: 12px;
                        color: #c1c1c1;
                    }
                }
                .col-form-label {
                    font-size: 12px;
                    font-weight: 600;
                    padding-top: 3px;
                    padding-bottom: 1px;
                    text-align: right;
                }
                .col-sm-7 {
                    label.eligibility-condition-note {
                        margin-bottom: 0px;
                        font-size: smaller;
                        font-weight: 500;
                    }

                    &.condition-desc {
                        padding-bottom: 20px;
                    }

                    &.eligibility-category-list {
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }
}

// End Eligibility //
.certification-box {
    border: 1px solid lightgrey;
    border-radius: 2px;
    background-color: #F5F5F7;
    height: 180px;
}

.second-row-certication {
    height: 305px;
}

.third-row-certication {
    height: 250px;
}

.first-column-second-row-certication {
    margin-top: 16px;
    height: 110px;
}

.first-row-certication {
    .table-component .table-component-body {
        overflow: hidden;
        flex-basis: 160px;
    }
}

.table-component-header h4 {
    font-weight: bold;
    font-size: inherit;
}

.certification-header {
    font-weight: 500;
    font-size: large;
    padding: 10px;
}

.rider-certification-form-label {
    text-align: right;
    font-weight: 600;
}

.rider-certification-form-grid-div {
    width: 100%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    h6 {
        font-weight: 600;
        font-size: 14px;
        padding: 10px 0 5px 20px;
        line-height: 20px;
        font-family: 'AvenirNext';
    }
    .col-form-label-sm {
        font-size: 12px;
        font-family: 'AvenirNext';
    }
}

.rider-certification-form-header {
    font-size: inherit;
    font-weight: bold;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.appeal-process-textarea {
    textarea {
        border: 1px solid #d4d6d7;
        border-radius: 2px;
        background-color: white;
        margin-top: 5px;
        font-size: 12px;
    }
}

.rider-certification-form-border {
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    input {
        border: 1px solid #d4d6d7;
        border-radius: 2px;
        height: 22px;
        background-color: white;
    }
    textarea {
        border: 1px solid #d4d6d7;
        border-radius: 2px;
        background-color: white;
        margin-top: 3px;
    }
    .text-area-container {
        height: 120px;
    }
    .ember-power-select-trigger {
        margin-top: 3px;
        font-size: 12px;
    }
     ::placeholder {
        font-size: 12px;
    }
}

.textarea-form {
    padding-right: 6px;
    resize: none;
    padding-bottom: 10px;
}

.function-assessment {
    font-weight: 600;
    label {
        font-family: 'AvenirNext';
        font-size: 12px;
    }
    .heading {
        margin: 20px;
        line-height: 20px;
        font-style: inherit;
        font-size: 14px;
        font-weight: 600;
        font-family: 'AvenirNext';
    }
    .input-box {
        input,
        textarea {
            padding-left: 8px;
            border-radius: 2px;
            margin-right: 20px;
            font-size: 12px;
        }
        .text-area-container {
            display: flex !important;
        }
    }
    .content-box {
        .text-area-container {
            textarea {
                height: 160px;
                border: 1px solid #D4D6D7;
                border-radius: 2px;
                margin-right: 20px;
                font-size: 12px;
            }
        }
    }
    .phone-box {
        .form-component-input-mask-layout input {
            height: 25px;
            border-radius: 2px;
        }
    }
}

.doctor-infomation {
    font-weight: 600;
    label {
        font-family: 'AvenirNext';
        font-size: 12px;
    }
    .heading {
        margin: 20px;
        line-height: 20px;
        font-style: inherit;
        font-size: 14px;
        font-weight: 600;
        font-family: 'AvenirNext';
    }
    .input-box {
        border: 1px solid #D4D6D7;
        padding-left: 8px;
        border-radius: 2px;
    }
    .phone-stl {
        font-size: 12px;
    }
    .phone-box {
        .form-component-input-mask-layout input {
            height: 25px;
            border-radius: 2px;
        }
    }
    .small-phone-box {
        .form-components-text input {
            height: 25px;
            border-radius: 2px;
        }
    }
}

//Rider-Image-Upload
.ember-modal-overlay.translucent.image-detail-overlay {
    z-index: zindex(action-modal);
}

.image-detail-container {
    width: 55%;
    padding: 0;
}

.add-rider-image-detail-wrapper {
    .add-image-detail-header {
        background: #ffffff;
        box-shadow: 0 2px 4px rgba(199, 199, 199, 0.5);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        justify-content: space-between;
        padding: 4px 20px;
        .actions {
            color: #a7b4b9;
            vertical-align: middle;
            i {
                margin-left: 12px;
                cursor: pointer;
            }
        }
        .add-image-title {
            font-weight: 600;
            font-size: 16px;
            color: #303e44;
        }
    }
    .add-image-detail-body {
        background-color: black;
        height: 300px;
        overflow: hidden;
    }

    .image-detail-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        span.image-detail-note {
            margin-left: 14px;
        }
    }
    
    .btn {
        background: linear-gradient(180deg, #f9f9f9 0%, #d1d1d1 100%);
        border: 1px solid #d4d6d7;
        box-sizing: border-box;
        border-radius: 2px;
        margin: 7px 4px;
        width: 126px;
        font-style: normal;
        font-size: 14px;
        font-weight: 600;
        line-height: 10px;
        text-align: bottom;
        color: #303e44;
        height: 26px;
        margin-top: 20px;
        margin-right: 10px;
        padding-bottom: 5px;
    }
    .btn-div {
        height: 70px;
        float: right;
        padding-right: 8px;
        label {
            .imageInputFile {
                display: none;
            }
        }
    }
    .uploaded-image {
        position: relative;
        width: 34%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border: 0;
        bottom: 10%;
        margin-top: 5%;
    }
    .img-div {
        text-align: center;
    }
}

.notifications {
    .container {
        h6 {
            font-size: 12px;
            font-weight: 600;
        }
        p {
            font-size: 12px;
            font-weight: 500;
        }
    }
    .powerselect-box {
        .ember-power-select-selected-item {
            font-size: 12px;
        }
    }
    .ember-checkbox {
        height: 10px;
    }
    .generic-panel .panel-title {
        font-size: 16px;
        padding: 12px;
    }
    .sub-heading {
        font-size: 12px;
        font-weight: 600;
    }
    .toggleSwitch {
        .toggle-switch {
            &.is-active .track {
                background-color: #89d152;
                &:after {
                    content: 'Yes';
                    font-size: 15px;
                    font-family: Avenir Next;
                    color: white;
                    right: 22px;
                }
            }
            .track {
                width: 55px;
                height: 20px;
                background-color: #A7B4B9;
                &:after {
                    content: 'No';
                    font-size: 15px;
                    font-family: Avenir Next;
                    color: white;
                    position: absolute;
                    right: 10px;
                    bottom: -2px;
                }
            }
            .button {
                height: 22px;
                width: 22px;
            }
        }
    }
    .activity-table {
        .table-component .table-component-body {
            overflow: hidden;
            flex-basis: 160px;
        }
    }
}


.mail-notifications{
    .container {
        h6 {
            font-size: 12px;
            font-weight: 600;
        }
        p {
            font-size: 12px;
            font-weight: 500;
        }
    }
    .generic-panel .panel-title {
        font-size: 16px;
        padding: 12px;
    }
    .sub-heading {
        font-size: 12px;
        font-weight: 600;
    }
    }

    .mailNotification {
    text-align: left;
    font-family: $ui-font;
    font-size: $widget-body-font-size;
    color: black;
    line-height: normal;

    &.select {
        width: 100%;
        height: 21px;
        border: 1px solid rgba(38, 50, 56, 0.2);
        border-radius: 2px;
        background-color: #fff !important;
        color: #303E44;
        cursor: pointer;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 5px 0 5px;
        outline: none;


        &:hover {
            background-color: #F1F1F1;
        }

        .title {
            color: $title-color;
            font-weight: 500;
            flex-basis: 110px;
            flex-grow: 1;
            text-overflow: ellipsis;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
        }

        span {
            color: $title-color;
            flex-grow: 0;
            left:94%;
            top:0px;
            bottom:0px;
            width:0px;
            margin: auto;
            position: absolute;
            display: inline-block;
            border-width: 4px 4px 0 4px;
            border-color: #303e44 transparent transparent transparent;
            i {
                color: #aaaaaa !important;
            }
        }
    }

    &.options {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        list-style: none;
        box-sizing: content-box;
        height: auto;
        border: 1px solid rgba(38, 50, 56, 0.2);
        border-radius: 2px;
        background-color: #FFFFFF;
        overflow: auto;
        max-height: 420px;

        .option {
            color: #303E44;
            font-family: $ui-font;
            padding: 6px 2px 0px 2px;
            position: relative;

            // Checkboxes
            input[type="checkbox"] {
            position: absolute;
            left: -9999px;

                & + label {
                    position: relative;
                    display: inline-block;
                    padding: 0px 0px 0px 22px;
                    cursor: pointer;
                    width: 100%;
                    font-weight: 500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 0;

                    &.addressHeader{
                        font-size: 12px;
                        font-weight: 600;
                    }

                    // Default State
                    &:before {
                        display: block;
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        margin: (-$checkbox-width/2) 0 0;
                        width: $checkbox-width;
                        height: $checkbox-width;
                        background-size: 72px auto;
                        -webkit-background-size: 72px auto;
                        -moz-background-size: 72px auto;

                        box-sizing: border-box;
                        border: 1px solid rgba(38,50,56,0.2);
                        border-radius: 2px;
                        background-color: #FFFFFF;
                    }
                }

                // Checked State
                &:checked + label {
                    &:before {
                        box-sizing: border-box;
                        height: auto;
                        border: 1px solid rgba(38,50,56,0.2);
                        border-radius: 2px;
                        width: $checkbox-width;
                        height: $checkbox-width;
                        background-color: #0042fd;
                        color:#FFF;

                        font-family: FontAwesome;
                        font-weight: 200;
                        content: "\f00c";
                        font-size: 9px;
                        text-align: center;
                    }
                }

                // Disabled State
                &:disabled {
                    & + label {
                    cursor: not-allowed;

                        &:before {
                            background-color: #CCC;
                        }
                    }

                    // Disabled Checked
                    &:checked + label {
                        &:before {
                            background-color: #CCC;
                        }
                    }
                }


                // hover State
                &:hover + label {
                    &:before {
                        box-sizing: border-box;
                        height: auto;
                        border: 1px solid rgba(38,50,56,0.2);
                        border-radius: 2px;
                        width: $checkbox-width;
                        height: $checkbox-width;
                        background-color: #f1f1f1;
                        color:#ccc;

                        font-family: FontAwesome;
                        font-weight: normal;
                        content: "\f00c";
                        font-size: 9px;
                        text-align: center;
                    }
                }

                // hover State checked
                &:checked + label:hover {
                    &:before {
                        background-color: #1b1b1b;
                        font-weight: 600;
                        color:#FFF;
                    }
                }
            }
        }
    }
}

.add-address-component {
    .locations-input {
        .cell.button {
            height: 22px;
            line-height: 1;
        }
    }
}

.rm-field-error {
    border: 2px solid #d13730;
}
