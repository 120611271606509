.trip-activity-log {
  height: 100%;

  display: flex;
  flex-direction: column;

  .header-sub-header{
    width: 100%;
    padding: 10px 10px 0px 10px;
    font-size: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    button {
      width: 100%;
    }
  }
  .reconcile-drawer-body{
    margin: 0px 5px;
    padding-top: 0px !important;
    background-color: #fff;
    overflow: auto;   
    padding-left: 0 !important;
    padding-right: 0 !important;
    table {
      tr{
        &:nth-child(even) 
        {
          background-color: #fff;
        } 
        &:nth-child(odd){
          background-color: #f5f5f7 !important;
        }
        th{
          border-bottom: 1px solid #d8d8da;
        }
        td, th{
          padding: 5px;
        }
      }      
    }
    .reconcile-table {
      min-height: 500px;
      border: 1px solid #d8d8da;
    }
  }


  .g-side-drawer-body {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;

    font-size: 12px;

    .ember-light-table {
      flex: 1 0 100px;
    }

    .lt-row {
      &:nth-child(2n-1) {
        background-color: $table-row-alt-background-color;
      }

      &.is-selected {
        background-color: $table-row-selected-color !important;
      }
    }

    .lt-cell {
      vertical-align: top;
      overflow-wrap: break-word;
    }
    .reconcile-table .lt-cell {
      word-break: break-all;
    }
  }
}
