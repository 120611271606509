// secondary menu (workspace navigation) background colors
$workspace-menu-background-light: $background-lighter;
$workspace-menu-background-dark: black;

.workspace-nav-wrapper {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  flex: 0 0 $workspace-nav-height;
  z-index: 100;

  .workspace-navbar {
    display: flex;
    height: $workspace-nav-height;
    background-color: $background-lighter;
    color: dds-greys("800");
    padding: 5px 0 5px 20px;
    font-weight: 500;
    font-size: 14px;

    .workspace-logo {
      margin-right: 2rem;
      white-space: nowrap;
      .fa {
        color: #a7b3b9;
        font-weight: normal;
      }
      .fa-th:before {
        content: "\f00a";
      }
    }

    .datetimeflatpickr-wrapper {
      flex: 0 0 200px;
      .flatpickr-input {
        width: 100px;
        height: 22px;
        font-size: 12px;
      }
    }

    .re-optimize-btn {
      @include button;
      height: 22px;
      font-size: 12px;
      font-weight: 500;
    }

    .upload-btn {
      @include button;
      height: 22px;
      font-size: 12px;
      font-weight: 500;
    }

    .reoptimize-information {
      display: flex;
      align-items: baseline;
      flex: 1 1 auto;
      p {
        margin: 0;
      }
    }

    .advance-notification-group {
      display: flex;
      align-items: center;
      margin-right: 12px;

      p.advance-notification-label {
        margin: 0 10px 2px 0;
      }

      .advance-notification-switch {
        border: solid 1px rgba(38, 50, 56, 0.2);
        border-radius: 5px;
        background-color: white;
        height: 30px;
        display: flex;
        align-items: center;
        width: 94px;

        button {
          border-radius: 0.25rem;
          cursor: pointer;
          text-align: center;
          text-decoration: none;
          border: solid 1px rgba(38, 50, 56, 0.2);
          color: white;
          vertical-align: middle;
          height: 25px;

          &.send-button-hidden {
            visibility: hidden;
            margin-left: 3px;
          }

          &.send-button {
            background-color: #689f38;
            margin-left: 3px;
          }

          &.sent-button {
            background-color: #b4b4b4;
            margin-right: 3px;
          }
        }
      }
    }

    #header-left {
      display: flex;
      align-content: center;
      align-items: center;
      flex: 1 1 auto;
    }
    #header-center {
      display: flex;
      align-content: center;
      align-items: center;
      flex: 1 1 auto;
    }
    #header-right {
      display: flex;
      align-content: center;
      align-items: center;
      flex: 1 1 auto;
    }

    .notification-failure-sec-window{
      display: contents;

      .secondary-window {
        z-index: 9100;
    
        .secondary-window-modal {
          overflow-y: hidden;
          display: block;
          background-color: white;
          min-width: 52vw;
          min-height: 30vh;
          max-height: 50vh;
          height: auto;

          .secondary-window-header {
            flex-basis: 34px;
            flex-grow: 0;
            flex-shrink: 0;
            padding: 4px 0 0 6px;
            overflow-y: hidden;
            display: flex;
            justify-content: space-between;
            background-image: unset;
            background-color: white;
            color: black;

            .close-button {
              margin-right: 7px;
            }
          }

          .secondary-window-body {
            flex-basis: 34px;
            flex-grow: 0;
            flex-shrink: 0;
            overflow-y: hidden;
            display: flex;
            justify-content: space-between;
            background-image: unset;
            background-color: white;
            color: black;
            height: 93%
          }
        }
      }
    }

    .notification-failure-result-modal {
      .mb-4 {
        .no-gutters {
          .mt-1, .offset-md-1, .col-md-10 {
            margin: 0 !important;
            max-width: 100% !important;
            flex: none;
            max-height: 47vh;
            overflow-y: auto;

            table thead td {
              background-color: #f2f2f2;
              padding: 1px 0 1px 7px !important;
            }

            table tbody td {
              padding: 1px 0 1px 7px !important;
              border: 0;
            }

            tr:nth-child(even) {
              background-color: #f2f2f2;
            }

            tr:nth-child(odd) {
              background-color: white;
            }
          }
        }
      }
    }
  }

  // NYAAR-15990 - added 'schedule-dashboard' class on application level to display blue banner while reoptimization/exporting is happening and if workspace is "schedule"
  &.schedule-dashboard {
    .workspace-navbar {
      &.reoptimize-in-progress {
        background-color: $info-dds;
        color: $white;
      }
      &.is-exported {
        background-color: $info-dds;
        color: $white;
      }
    }
  }
}

.header-fields-group {
  width: 100%;
  justify-content: flex-start !important;
  align-items: center !important;
}

.header-fields-group > div {
  display: flex;
  align-items: center;
  padding: 0 15px 0 5px;
}

.header-fields-group .v-divider:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 28px;
  background-color: #d4d6d7;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.header-fields-group .v-divider {
  position: relative;
}

.header-fields-group label {
  margin: 0 10px 0 0;
  font-size: 12px;
}

.header-fields-group input,
.header-fields-group .ember-power-select-trigger {
  border-radius: 2px;
  border: 1px solid rgba(38, 50, 56, 0.2);
  font-size: 12px;
  padding: 1.01px 6px 1px;
}

.header-fields-group .ember-power-select-trigger {
  line-height: 1.5;
}

.search-group .ember-power-select-trigger {
  padding: 1.01px 6px 1px;
  line-height: 1.5;
  border-radius: 2px;
  border: 1px solid rgba(38, 50, 56, 0.2);
  font-size: 12px;
  background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
}

.ember-power-select-selected-item,
.ember-power-select-placeholder {
  margin-left: 0px;
}

.header-fields-group .btns-group button {
  border: 1px solid #b4b4b4;
  margin: 0 0 0 8px;
  border-radius: 2px;
  height: 26px;
  color: #303e44;
  font-weight: 500;
  background-color: #f7f7f7;
  font-size: 14px;
  background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
  line-height: 1.2;
  padding: 0 6px;
}

.header-fields-group .btns-group button.disabled {
  color: rgba(48, 62, 68, 0.5);
}

.flex-1 {
  flex: 1;
}

.header-fields-group .btns-group .add-route-btn {
  font-size: 12px;
  width: 90px;
  border: 1px solid rgba(38, 50, 56, 0.2);
  height: 22px;
  background-color: #fff;
}

.width-100 {
  width: 100px;
  .ember-power-select-trigger {
    width: 100px;
  }
}

.width-160 {
  width: 160px;
  .ember-power-select-trigger {
    width: 160px;
  }
}

.width-135 {
  width: 135px;
  .ember-power-select-trigger {
    width: 135px;
  }
}

.width-75 {
  width: 75px;
  .ember-power-select-trigger {
    width: 75px;
  }
}

.reconcile-header {
  height: 40px;
}

.reconcile-header .workspace-navbar {
  flex: 1;
}

.search-group {
  display: flex;
  margin-left: 20px;
  height: 22px;
  align-items: center;
}

.loader-icon {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  z-index: 9999;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
}

.reconcile-header-btn .ember-power-select-trigger {
  background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%) !important;
}

.reconcile-header-btn {
  .btns-group button {
    height: 22px;
    font-size: 12px;
  }
}



