// we need to define these hard values into variables
.ember-power-select-trigger {
  padding: 3px 7px 0 7px;
  border: 1px solid #cdd4d6;
  border-radius: 3px;
  overflow: hidden;

  &:focus {
    background-color: #fff;
    border-color: #bbda9b;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(124, 179, 66, 0.25);
  }

  &:disabled {
    cursor: not-allowed;
  }
  &:aria-disabled {
    cursor: not-allowed;
  }
}

.ember-power-select-trigger--active {
  background-color: #fff;
  border-color: #bbda9b;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(124, 179, 66, 0.25);
}