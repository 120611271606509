

.address-search-component {
  flex-grow: 1;

  display: flex;

  input {
    flex-grow: 1;
  }

  // specific to column widget header
  input {
    border: none;

    &:focus {
      outline: none;
    }
  }

  .address-search-dropdown {
    position: absolute;

    background: white;

    top: $widget-header-height + 4px;

    left: 4px;
    width: calc(100% - 8px);
    max-height: 200px;

    overflow-y: auto;

    z-index: 4000;

    border-top: 1px solid rgba($widget-button-color, .5);
    border-bottom: 1px solid rgba($widget-button-color, .5);

    .loading-message,
    .no-results-message {
      padding: 4px;
      font-size: 12px;
    }

    .address-search-result {
      // border-top: 1px solid $widget-button-color;

      font-size: 12px;
      padding: 4px 8px;

      &:hover {
        background-color: dds("600");
        cursor: pointer;
      }
    }
    .selectedAddress {
      background-color: dds("600");
    }
  }

  &.has-selection {
    input {
      font-weight: bold;
    }
  }
}