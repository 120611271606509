.vehicle-lookup-view{
  label {
    margin: 0;
    vertical-align: middle;
  }
  input {
    padding: 3px 7px 3px 7px;
    &:disabled {
      cursor: not-allowed;
      color: #aeaeae;

      &:hover {
        color: #aeaeae;
      }
    }
  }
  .generic-panel {
    font-size: 12px;
    font-weight: 500;
    flex: 1 1 auto;

    .panel-body {
      padding: 0 4rem 0 4rem;

      .row {
        align-items: center;
      }

    }
  }
}