.change-credentials {

  .user-info-label {
    width: 150px;
    line-height: 40px;
    text-align: right;
    color: #303E44;
    font-size: 20px;
    font-family: $ui-font;
    font-weight: 600;
  }

  .user-info-row {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 15px;
  }

  .user-info-value {
    padding: 10px;
    font-size: 20px;
    color: #303E44;
    font-family: $ui-font;
    font-weight: 600;
  }

  .divider-container {
    font-size: 30px;
    color: #303E44;
    text-align: center;
    line-height: 1;
  }

  .password-input-container {
    padding-left: 4px;
    padding-right: 4px;
    background: #F0F1F2;
    border-radius: 4px;
    overflow: hidden;
    border: 1px #D4D6D7 solid;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .password-input {
    border:none;
    background: transparent;
    color: #303E44;
    font-size: 20px;
    font-family: $ui-font;
    font-weight: 600;
    line-height: 40px;
    width: 200px;
  }

  .button-container {
    display: flex;
    justify-content: right;
    gap: 40px;
    margin-right: 30px;
  }

  .custom-button {
    padding-left: 20px;
    padding-right: 20px;
    background: linear-gradient(269deg, #9CB3B6 0%, #727272 100%);
    box-shadow: 0px 2px 5px rgba(38, 50, 56, 0.50);
    border-radius: 10px;
    color: $icon-light-color;
    font-size: 20px;
    font-family: $ui-font;
    font-weight: 600;
    line-height: 30px;
    transition: background 0.3s ease, opacity 0.3s ease, transform 0.1s ease;

    &:hover {
      color: $icon-light-color;
      background: $dds-primary-gradient;
      transition: color 0.2s;
      opacity: 1;
      transform: scale(1.01);
    }

    &:disabled {
      opacity: 0.6;
    }

    &:active {
      transform: scale(0.99);
    }
  }

  .password-button {
    padding-left: 20px;
    padding-right: 20px;
    background: linear-gradient(269deg, #9CB3B6 0%, #727272 100%);
    box-shadow: 0px 2px 5px rgba(38, 50, 56, 0.50);
    border-radius: 10px;
    color: white;
    font-size: 20px;
    font-family: $ui-font;
    font-weight: 600;
    line-height: 30px;
  }

}
