.application-wrapper {

  height: 100vh; /* Ensures the container takes up at least the full height of the viewport */
  display: flex;
  flex-direction: column;
  background-image: url('/background.png'); /* Path to your image in the public directory */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .header-nav-wrapper {
    flex: 0 0 $nav-height;
    z-index: 1;
  }

  .content-wrapper {
    flex: 1 0 0;
    overflow: auto;
  }

  .content-wrapper.maximize-widget-only {
    flex: 1 0 0;
    overflow: hidden;
  }
}
