.ember-text-field-holder {
  &.error {
    input {
      border: 2px solid $otp-danger !important; 
    }
  }

  input {
    @include edit-input();
    text-overflow: ellipsis;
    &:disabled {
      background-color: transparent;
      cursor: not-allowed;
    }
    &:read-only {
      background-color: transparent;
      cursor: not-allowed;
    }
  }

  &.error {
    input {
      border: 2px solid $otp-danger !important;
    }
  }
}