.add-break-side-drawer{
  height: inherit;
  overflow-y: auto;
  .add-break-layout {
    button {
      @include button();
    }
  }
  .g-side-drawer-body {
    margin-top: 8px;
    background-color: white;
    .g-side-drawer-panel.is-collapsible.is-expanded, .g-side-drawer-panel.is-initial{
      .g-side-drawer-panel-body {
        display: flex;
        flex-direction: column;
        padding: 0px 0 0px 0px;
        color: #303E44;
        .view-impact-table-wrapper {
          overflow: auto;
          flex-basis: 200px;
          flex-grow: 1;
          flex-shrink: 1;
          width: calc(100% - 0px);
          font-size: 12px;
          background-color: #fff;
          .ember-light-table {
            overflow: visible;
            font-feature-settings: "tnum";
            width: fit-content;
          }
          thead th{
            width: 50px;
          }
        }
        .g-side-drawer-panel-selector {
          h5 {
            width: 66px;
            margin: 0px 14px 0 15px;
          }
          .dropdown {
            .ember-power-select-trigger {
              width: 155px;
            }
          }
        }
        input#override {
          margin-left: 10px;
        }
        label {
          margin: 5px 0px 7px 9px;
          font-size: 14px;
          font-weight: bold;
        }
        input[type="checkbox"] {
          vertical-align: bottom;
          position: relative;
          top: -4px;
          overflow: hidden;
        }
      }
    }
  }
  .g-side-drawer-panel-form {
      position: relative;
      width: 73%;
    input{
      &.error {
        border: 2px solid $otp-danger !important;
      }
    }
  }
 .toggle-switch {
    height: 39px
  }
  .error-message {
    color: #E02020;
    font-weight: bold;
    &.padded {
      padding: 0 0.75em;
    }
  }
}

.scheduling-add-break-side-drawer {
  height: inherit;
  overflow-y: auto;

  .break-info-label{
    font-size: 12px;
  }
  .break-type-info-label{
    font-size: 12px;
    text-align: right;
    font-weight: 700;
  }
  .locations-input .cell.button {
    height: 24px;
  }
  .g-side-drawer-panel-body{
    input{
      &.error {
        border: 2px solid $otp-danger !important;
      }
    }
  }
  .remove-input-border{
    input {
      border: none!important;
    }
  }
}