// Variables
$button-width: 90%;
$button-height: 70px;

#sandbox-setup {
  .row {
    display: flex;
    justify-content: center;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    margin-top: 100px;
  }

  .page-container{
    width:100%;
	  height:500px;
    padding: 20px;
    align-items: center;
    justify-content: center;
  }

  .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    border: none;
    padding: 0;
    border-radius: 20px;
    width: $button-width;
    height: $button-height;
    justify-content: center;

    &:disabled {
      opacity: 1;
    }
  }

  .btn-text {
    color: #303E44;
    width: $button-width;
    font-size: $font-size-sm-dds;
    font-family: $ui-font, sans-serif;
    font-weight: $font-weight-base-dds;
    line-height: 26px;
    text-align: center;
    word-wrap: break-word;
    border: none;
    background: transparent;
    padding: 10px;
  }

  .check-icon {
    color: white;
    width: 100%;
    height: $button-height;
    background: $dds-primary-gradient;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }

  .bounding-box {
    box-shadow: 0 0 0 2px #00aeff !important;
    background-color: rgba(145, 206, 235, 0.3) !important;
    transition: all 0.3s ease-in-out;
    z-index: 1;
  }

  .optimize-strategy-container {
    background: white;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    padding: 20px;
    width: 390px;
    height: 410px;
  }

  .optimize-strategy-heading {
    color: #303E44;
    font-size: 30px;
    font-family: $ui-font, sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .radio-button-input {
    display: flex;
    align-items: center;
    gap: 20px;
  }


  .radio-button-input input[type="radio"] {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .radio-button-input input[type="radio"]:checked {
    accent-color: #679F38;
  }

  .radio-button-label {
    font-size: 22px;
    font-family: $ui-font, sans-serif;
    font-weight: 600;
    color: #303E44;
    align-self: center;
    margin: 0;
    padding: 0;
    line-height: 1; /* Adjust line height to match the radio button */
  }

  .optimize-btn-container{
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  .optimize-btn {
    width: 125px;
    height: 55px;
    box-shadow: 0px 2px 5px rgba(38, 50, 56, 0.50);
    padding: 10px;
    margin: 10px;
    border-radius: 8px;
    text-align: center;
    font-size: 22px;
    font-family: $ui-font, sans-serif;
    font-weight: bold;
    display: inline-block;
  }

  .optimize-btn-no-selection {
    background-color: white;
    color: black;
    cursor: not-allowed;
    pointer-events: none;
  }

  .optimize-btn-selected {
    background: $dds-primary-gradient;
    color: white;
    cursor: pointer;
    pointer-events: auto;
  }

  .global-spinner {
    background: rgba(255, 255, 255, .2);

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: zindex(modal);
  }

}

.vertical-line-with-or {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 43px;

  div.vertical-line{
    width: 9px;
    background-color: black;
    height: 100%;
    float: left;
    border-radius: 10px;
  }

  .btn-or-text {
    color: #F0F8FB;
    background-color: black;
    border: 3px solid black;
    border-radius: 33px;
    font-size: 29px;
    margin: 8px 0px 8px 0px;

    .btn-or-text-inside {
      padding-top: 9px;
      padding-bottom: 9px;
    }
  }
}
