.vehicle-view {
  .form-panel-wrapper {
    margin: 0 0.5rem;
    min-height: 600px;

    .col {
      display: flex;
      flex-direction: column;
      padding: 0;

      .driver {
        flex: 1 1;
      }

      .emergency {
        flex: 1 1;
      }
    }
  }

  .generic-panel {
    flex: 1 1;
    .panel-body {
      font-size: 12px;
      font-weight: 600;

      .container {
        padding: 0 2rem 0 2rem;

        .row {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 0.5rem;
          align-items: center;

          label {
            margin: 0;
          }

          .ember-power-select-trigger {
            overflow: hidden;
          }

          .datetimeflatpickr-wrapper {
            height: 25px;
            input {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .notes-panel {
    .ember-text-area {
      height: 160px;
    }
    .text-area-container {
      height: 160px;
    }
  }

  .error {
    input,
    .ember-power-select-trigger {
      border: solid 2px $otp-danger !important;
    }
  }
}
