.playback-widget {
  $column-title-margin: 6px;
  display: flex;
  flex-direction: column;
  padding: $widget-border-width 0 $widget-border-width $widget-border-width;
  font-family: $ui-font;
  hr {
    border-color: #ccced0;;
  }
  .btn-group {
    width: 87%;
  }
  .playback-widget-header {
    padding: $widget-header-mobile-padding;
    flex-basis: $widget-header-mobile-height;

    flex-basis: $widget-header-height;
    flex-grow: 0;
    flex-shrink: 0;
    padding: $widget-header-padding;
    overflow-y: hidden;
    display: flex;
    justify-content: space-between;
    background-color: $table-header-background-color;

    .playback-widget-title {
      flex-grow: 1;
      flex-shrink: 0;
      line-height: $widget-header-height - (2 * $widget-header-padding);
      margin: 0 0 0 $column-title-margin;
      padding-left: 7px;
      font-weight: 600;
      font-size: 16px;
    }

    .playback-widget-location-search-box,
    .playback-widget-search-box {
      padding: 6px 21px 6px 6px !important;
      margin: 1px 24px 1px 6px;
      border: 1px solid #e6e6e6;
      height: 36px;
      margin-top: -5px;

      flex-grow: 1;
      flex-shrink: 0;
      height: $widget-header-height - (2 * $widget-header-padding) - 2px;
      margin: 1px 2px 1px $column-title-margin;
      padding: 3px 5px 3px 5px;
      border-radius: 5px;
      font-size: $widget-input-font-size;
      background-color: transparent;
      border: none;
      outline: none;
    }
    .playback-widget-location-search-box {
      padding: 0 21px 0 0;
    }
    .playback-widget-location-search-dropdown {
      background: red;
      z-index: 1000;
    }

    .playback-widget-controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      flex-shrink: 0;
      // height: 26px;
      margin-right: $column-title-margin;
      margin-left: auto; // helpful if we need to change .column-widget-header's justify-content attribute
      cursor: default;

      .playback-widget-button {
        flex: 0 0 30px;
        padding: 0 5px 0 5px;
        margin-right: 8px;
        font-size: 18px;
        display: inline-block;
        background-color: inherit;
        border: none;
        color: $widget-button-color;
        opacity: 0.5;
        cursor: pointer;
        outline: none;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          transition: color 0.2s;
          opacity: 1;
        }

        &.disabled {
          opacity: 0.2;
          pointer-events: none;

          &:hover {
            color: $widget-button-color;
            transition: none;
            opacity: 0.2;
          }
        }

        &:focus {
          box-shadow: 0 0 1px 1px $table-cell-selected-color;
          border-radius: 4px;
          opacity: 1;
        }
      }
      .active-button {
        transition: color 0.2s;
      }

      .playback-widget-controls-divider {
        flex: 0 0 1px;
        height: 20px;
        margin: 0 8px 0 0;

        background-color: $widget-button-color;
        opacity: 0.5;
      }

      .reset-button {
        opacity: unset;
      }
    }

    .playback-widget-distance-box {
      margin: 0 0 0 $column-title-margin;
      font-size: $widget-title-font-size;
      line-height: $widget-header-height - (2 * $widget-header-padding);
      font-weight: 600;
    }
  }

  .playback-widget-body {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 446px;
    width: calc(100% - #{$widget-border-width});
    overflow-x: auto;
    flex-direction: row;
    display: flex;
    border-bottom: 1px solid #ccced0;

    .playback-widget-tabs {
      flex: 0 1 auto;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 0 10px;
      background-color: white;
      border-radius: 4px 4px 0 0;
      border-bottom: 0;
      min-height: 31px;
      box-shadow: 0 2px 4px 0 rgba(199, 199, 199, 0.5);

      .divider {
        height: 16px;
        margin: 0 24px;
      }

      .tab {
        width: 50%;
        font-size: 12px;
        display: flex;
        justify-content: center;
        padding-left: 12px;
        padding-right: 12px;
        color: rgba(48, 62, 68, 0.5);
        background-color: white;
        padding-top: 3px;
        border-width: 0 0 2px 0;
        border-color: transparent;
        white-space: nowrap;
        -webkit-appearance: none;
        outline: none;
        font-weight: bold;

        &.active {
          color: #586368;
          font-weight: bold;
          border-bottom: 2px solid #75b643; // #586368;
        }
      }
    }
    .playback-widget-form {
      flex: 1 0 auto;
      display: flex;
      background-color: #f5f5f7;
      margin: 0px;
      padding: 0px;
      flex-direction: column;
      td,
      th {
        padding-top: 3px;
        padding-bottom: 3px;
      }
      .form-container {
        background-color: #f5f5f7;
        border-top: 2px solid rgba(199, 199, 199, 0.5);
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 0px;
        padding: 18px 15px 10px;
        font-weight: 800;
        font-size: 12px;
        display: grid;

        // &:nth-child(odd) {
        //   border-bottom: 1px solid #ccced0;
        // }

        .form-panel-header {
          display: flex;
          cursor: pointer;
          align-items: center;
          margin-bottom: 15px;
        }

        .form-panel-header-caret {
          flex: 0 0 20px;
          margin-right: 8px;
        }

        .form-panel-title {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .form-panel-form {
          padding: 0 16px;
          border: 0;

          margin-bottom: 12px;

          td:first-child {
            padding-right: 15px;
            padding-left: 2px;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.left {
              text-align: left;
            }

            width: 8em;
            min-width: 6em;
            max-width: 8em;
          }

          &.compact {
            th {
              width: 120px;
              text-align: left;
              font-weight: normal;
            }
          }

          td {
            font-size: 12px;
            width: 100%;

            &.right-padded {
              padding-right: 8px;
            }
          }

          tr {
            .form-components-playback-address-search {
              height: 27px;
              width: 100%;

              .places-table {
                border: none;
                margin-top: 0rem !important;

                span.ember-power-select-selected-item {
                  margin-left: 4px;
                }

                td.cell-label {
                  padding-right: 20px;
                  width: 8em;
                  min-width: 8em;
                  max-width: 8em;

                  font-size: 12px;
                  text-align: right;

                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  &.left {
                    text-align: left;
                  }
                }

                td.edit-cell {
                  font-size: 12px;

                  &.right-padded {
                    padding-right: 8px;
                  }
                }

                td.button-cell {
                  padding: 0px;

                  .places-inline-button {
                    width: 22px;
                    height: 22px;
                    padding: 1px 0px 0px 0px !important;
                    font-size: 16px;
                    margin: 3px 20px 2px 3px;
                    font: message-box;
                  }
                }

                div.ember-power-select-trigger {
                  height: 22px;
                  width: 100%;
                  font-weight: bold;
                  max-width: 10em;
                }
              }
            }

            .flatpickr-input {
              width: 80%;
            }
          }
        }

        .hint {
          text-align: right;
          color: #b8b8b8;
          float: left;
          width: 100%;
          font-size: 12px;
        }

        /* Floating column for inputs: 100% width */
        .col-100 {
          float: left;
          width: 100%;
          margin-top: 4px;
        }

        .form-row {
          margin-bottom: 8px;
        }

        /* Clear floats after the columns */
        .form-row:after {
          content: "";
          display: table;
          clear: both;
        }

        /* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
        @media screen and (max-width: 600px) {
          .col-25,
          .col-75,
          input[type="submit"] {
            width: 100%;
            margin-top: 0;
          }
        }

        .btn {
          background-color: inherit;
          padding: 4px 24px 4px 24px;
          font-size: 14px;
          color: #1c2023;
          cursor: pointer;
          float: left;
          margin: 3px;
          letter-spacing: 0.5px;
          font-weight: 500;
          width: calc(50% - #{2 * 4px});
          border-radius: 0.1rem;
          height: 26px;
          line-height: 1;
        }
        /* On mouse-over */
        .btn:hover {
          background: #eee;
        }

        .success {
          box-sizing: border-box;
          border: 1px solid #b4b4b4;
          border-radius: 2px;
          background: linear-gradient(180deg, #47a41b 0%, #2b6a0d 100%);
          box-shadow: 0 1px 2px 0 rgba(133, 133, 133, 0.5);
          color: #ffffff;
          background-repeat: no-repeat;
        }

        .success:hover {
          background-position: 0 0;
          background: #47a41b;
          -webkit-transition: background-position 1s ease-out;
          -moz-transition: background-position 1s ease-out;
          -o-transition: background-position 1s ease-out;
          transition: background-position 1s ease-out;
        }

        .info {
          color: dodgerblue;
        }
        .warning {
          color: orange;
        }
        .danger {
          color: red;
        }
        .default {
          box-sizing: border-box;
          border: 1px solid #b4b4b4;
          border-radius: 2px;
          background: linear-gradient(180deg, #f9f9f9 0%, #d1d1d1 100%);
          box-shadow: 0 1px 2px 0 rgba(133, 133, 133, 0.5);
          color: #363b42;
          color: #000;
        }
      }

      .hint {
        text-align: right;
        color: #b8b8b8;
        float: left;
        width: 100%;
        font-size: 12px;
      }

      /* Floating column for inputs: 100% width */
      .col-100 {
        float: left;
        width: 100%;
        margin-top: 4px;
      }

      .form-row {
        margin-bottom: 8px;
      }

      /* Clear floats after the columns */
      .form-row:after {
        content: "";
        display: table;
        clear: both;
      }

      /* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
      @media screen and (max-width: 600px) {
        .col-25,
        .col-75,
        input[type="submit"] {
          width: 100%;
          margin-top: 0;
        }
      }
    }
  }

  .playback-widget-header .playback-widget-controls .playback-widget-button {
    font-size: 20px;
  }

  .address-search-component .address-search-dropdown {
    top: 50px;
    .address-search-result {
      border-bottom: 1px solid #ccc;
      padding: 8px 8px;
      font-size: 14px;
      &:last-child {
        border-bottom: none;
      }
    }

    .no-results-message,
    .loading-message {
      font-size: 14px;
      padding: 8px;
    }
  }
  .ember-power-select-trigger,
  input[type="text"] {
    padding: 0px 5px;
    text-align: left;
    line-height: 1.74;
    height: 22px;
    width: 80%;
    border: 1px solid rgba(38, 50, 56, 0.2);
    border-radius: 2px;
    font-weight: 500;
    color: #000000e6;
  }
  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #999999;
  }
  input::-moz-placeholder { /* Firefox 19+ */
    color: #999999;
  }
  input:-ms-input-placeholder { /* IE 10+ */
    color: #999999;
  }
  input:-moz-placeholder { /* Firefox 18- */
    color: #999999;
  }
  .ember-power-select-placeholder,
  .ember-power-select-selected-item {
    padding: 0;
    height: 20px;
    width: 84px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }
  .address .ember-power-select-status-icon {
    display: none;
  }
  .ember-power-select-status-icon {
    border-color: #343a40e6 transparent transparent transparent;
  }
  .ember-text-field .ember-flatpickr-input .ember-view .flatpickr-input {
    height: 20px;
    width: 95px;
    color: #303e44;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }
  .ember-power-select-search-input {
    height: 20px;
    width: 84px;
    color: #303e44;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }

  .leaflet-control-recenter a,
  .leaflet-control-zoom a:last-child,
  .leaflet-control-zoom a:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  .leaflet-control-recenter a,
  .leaflet-control-zoom a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .leaflet-control-layers-toggle {
    width: 44px;
    height: 44px;
  }

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    font-size: 22px;
  }

  .leaflet-control-attribution,
  .leaflet-control-layers,
  .leaflet-bar {
    box-shadow: none;
  }

  .leaflet-control-layers,
  .leaflet-bar {
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
  }

  .leaflet-control-recenter a {
    font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 17px;
  }

  .crosshairs-big {
    top: 9px;
    position: absolute;
    right: 6px;
    padding: 0;
    margin: 0;
    font-size: 1.2em;
  }

  .flex-body-left {
    flex: 0 1 auto;
    flex-direction: column;
    display: flex;
    border: 1px solid #ccced0;
  }
  .flex-body-right {
    flex: 3 1 auto;
  }

  .grid-table-left {
    grid-column: col;
    margin: 0 5px 5px 5px;
    border: 1px solid #ccced0;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .grid-table-right {
    grid-column: col 2 / span 2;
    margin: 0 5px 5px 5px;
    border: 1px solid #ccced0;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .grid-table-all {
    grid-column: col;
    margin: 0 5px 5px 5px;
    border: 1px solid #ccced0;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .confirm-dialog {
    position: absolute;
    width: 438px;
    min-height: 173px;
    background-color: #263238;
    margin: 0 auto;
    top: 100px;
    left: 0;
    right: 0;
    z-index: 1000;
    border-radius: 4px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
  .top_0 {
    top: 0px;
  }
  .playback-widget-panel {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 40px;
    align-items: center;
    background-color: #f5f5f7;
    display: flex;
    width: calc(100% - #{$widget-border-width});
    height: 50px;

    .btn {
      background-color: inherit;
      font-size: 12px;
      cursor: pointer;
    }

    .circle-btn {
      display: inline-block;
      border: 0px;
      border-radius: 50%;
      min-width: 20px;
      min-height: 20px;
      text-align: center;
      box-sizing: content-box;
      white-space: nowrap;
      padding: 0px;
      line-height: 1;
      margin: 4px;
      outline: none;
      box-shadow: none;
    }

    .circle {
      display: inline-block;
      border-radius: 50%;
      min-width: 20px;
      min-height: 20px;
      text-align: center;
      box-sizing: content-box;
      white-space: nowrap;
      padding: 5px;
      line-height: 1;
    }

    .circle:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      padding-top: 100%;
      height: 0;
    }

    .circle:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      padding-top: 100%;
      height: 0;
    }

    .circle span {
      display: inline-block;
      vertical-align: middle;
      font-size: 10px;
      font-weight: bold;
    }

    /* On mouse-over */
    .btn:hover {
      background: #eee;
    }

    .success {
      box-sizing: border-box;
      border: 1px solid #b4b4b4;
      border-radius: 2px;
      background: linear-gradient(180deg, #47a41b 0%, #2b6a0d 100%);
      box-shadow: 0 1px 2px 0 rgba(133, 133, 133, 0.5);
      color: #ffffff;
      background-repeat: no-repeat;
    }

    .success:hover {
      background-position: 0 0;
      background: #47a41b;
      -webkit-transition: background-position 1s ease-out;
      -moz-transition: background-position 1s ease-out;
      -o-transition: background-position 1s ease-out;
      transition: background-position 1s ease-out;
    }

    .info {
      color: dodgerblue;
    }
    .warning {
      color: orange;
    }
    .danger {
      color: red;
    }
    .default {
      box-sizing: border-box;
      border: 1px solid #b4b4b4;
      border-radius: 50%;
      background: linear-gradient(180deg, #f9f9f9 0%, #d1d1d1 100%);
      box-shadow: 0 1px 2px 0 rgba(133, 133, 133, 0.5);
      color: #363b42;
      color: #000;
      margin: 4px;
    }
    .playback-slider-label {
      height: 14px;
      width: 64px;
      color: #5a8e32;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      max-width: 200px;
      padding: 0;
      text-align: center;
      background: none;
      border-radius: 0.25rem;
      position: absolute;
      z-index: 999;
      margin-top: 10px;
    }

    .panel-row {
      width: 100%;
      display: flex;
      height: 55px;
      justify-content: center;
      align-items: center;
      margin-top: 7px;
      margin-left: 220px;

      .playback-widget-slider {
        margin: 3px 10px;
        flex: 0 1 160px;
        align-items: center;
        display: flex;

        label {
          flex: 0 1 auto;
          margin-bottom: 0;
          padding-right: 4px;
          margin-right: 10px;
          padding-left: 4px;
          margin-left: 10px;
          white-space: nowrap;

          font-size: 13px;
          font-weight: 500;
        }

        .ui-slider {
          flex: 1 1 auto;
        }
      }
    }

    .slider {
      width: 368px;
    }

    .slider.slider-horizontal .slider-track {
      height: 5px;
    }

    .slider-track {
      top: 63% !important;
    }

    .slider-handle {
      left: 50%;
      width: 15px;
      height: 15px;
      border: 2px solid;
      border-color: white;
    }

    .slider-tick {
      width: 0px;
      height: 0px;
    }

    .slider-selection {
      height: 3px !important;
    }

    .slider-track-high {
      height: 3px !important;
    }
  }
  .confirm-dialog h2 {
    background: #171f22;
    background: -moz-linear-gradient(top, #334247 0%, #171f22 100%);
    background: -webkit-linear-gradient(top, #334247 0%, #171f22 100%);
    background: linear-gradient(to bottom, #334247 0%, #171f22 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324045', endColorstr='#182124',GradientType=0 );
    margin: 0;
    color: #fff;
    padding: 0 15px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    font-weight: 600;
    border-radius: 4px 4px 0 0;
  }
  .confirm-dialog p {
    color: #fff;
    font-size: 14px;
    padding: 20px 30px 10px;
    margin: 0;
    line-height: 19px;
    font-weight: 600;
  }
  .confirm-dialog .popup-footer {
    text-align: right;
    padding: 15px 30px;
  }
  .confirm-dialog .popup-footer button {
    width: 113px;
    height: 27px;
    border: 1px solid #fff;
    border-radius: 3px;
    background: #171f22;
    background: -moz-linear-gradient(top, #334247 0%, #171f22 100%);
    background: -webkit-linear-gradient(top, #334247 0%, #171f22 100%);
    background: linear-gradient(to bottom, #334247 0%, #171f22 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324045', endColorstr='#182124',GradientType=0 );
    color: #fff;
    font-size: 14px;
    padding: 0 10px;
    font-weight: 600;
    margin: 0 3px;
    line-height: 25px;
  }
  .confirm-dialog .dialog-close {
    position: absolute;
    top: 8px;
    right: 15px;
    color: #fff;
  }
  .playback-widget-table {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 300px;
    width: calc(100% - #{$widget-border-width});
    overflow: hidden;
    font-size: $widget-body-font-size;
    background-color: #f5f5f7; //$table-background-color;
    flex-direction: row;
    display: grid;
    .ember-table-overflow{
      height: 100%;
    }
    table {
      width: 100%;
      background-color: #fff;
      thead th {
        background-color: #f5f5f7;
        box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.2);
        font-weight: 700;
        color: rgba(48,62,68,0.8);
        padding: 2px 5px;
        white-space: nowrap;
      }
      td {
        padding: 2px 5px;
        white-space: nowrap;
        color: #263238;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      tr:nth-child(2n-1) {
        background-color: rgba(230, 231, 235, 0.4);
      }
      tr.is-selected {
        background-color: #7cb34245 !important;
      }
    }
    .playback-widget-table-header {
      flex-basis: 34px;
      flex-grow: 0;
      flex-shrink: 0;
      padding: 6px;
      overflow-y: hidden;
      display: flex;
      justify-content: space-between;
      background-color: white;
      position: relative;
      .playback-widget-table-title {
        flex-grow: 1;
        flex-shrink: 0;
        line-height: 22px;
        margin: 0 0 0 6px;
        padding-left: 0px;
        font-weight: 600;
        font-size: 16px;
      }
    }

    .playback-widget-table-body {
      width: calc(100% - 0px);
      overflow-x: auto;
      overflow-y: hidden;
      flex-direction: row;
      display: flex;
      height: 100%;
      position: relative;
      .message-container{
        position: absolute;
        top: 30px;
        width: 100%;
        z-index: 1000;
      }
    }

    .ember-light-table {
      overflow: visible;
      font-feature-settings: "tnum";
      width: fit-content;

      input[type="checkbox"] {
        font-size: 20px;
      }

      .lt-head-wrap {
        background-color: $table-column-header-background-color;
        /* box-shadow: 0px 0px 5px 0px $grid-color; */
        box-shadow: 0px 1px 5px rgba(38, 50, 56, 0.2);
        z-index: 1;
        overflow: visible;
        position: sticky;
        top: 0;
      }

      .lt-head {
        width: 100%;
        border-left: solid $table-header-left-border-width rgba(255, 255, 255, 0);

        th {
          padding: 2px 2px 2px 2px;
          th {
            padding: 4px;
          }
          font-weight: 700;
          opacity: 0.8;

          &:first-child {
            padding-left: 7px;
            input {
              float: left;
            }
          }
        }
        .priorityId {
          display: none;
        }
        // draw line for resize handle
        .lt-column-resizer {
          background: linear-gradient(
            to right,
            transparent 0%,
            transparent calc(50% - 1px),
            #ccc calc(50% - 1px),
            #ccc calc(50% + 0px),
            transparent calc(50% + 0px),
            transparent 100%
          ) !important;
          height: 70%;
          margin-top: 4px;
        }
      }

      .lt-column {
        &:focus-within {
          background-color: $table-cell-selected-color;
        }
      }

      .otp-danger .highlightable {
        color: $otp-danger;
      }

      .otp-warning .highlightable {
        color: $otp-warning;
      }

      .lt-row {
        border-left: solid $table-row-left-border-width rgba(255, 255, 255, 0);

        &:nth-child(2n-1) {
          background-color: $table-row-alt-background-color;
        }

        &.is-selected {
          background-color: $table-row-selected-color !important;
        }

        &.is-locked {
          background-color: lightgrey !important;
          color: grey;
        }

        &.lt-is-loading {
          text-align: center;
          background-color: transparent;
        }

        &.lt-no-data {
          background-color: transparent;
        }

        // Status Styles
        // &.late { background: $table-row-late-color; }
        // &.pending { background: $table-row-pending-color; }
        // &.ahead { background: $table-row-ahead-color; }

        &.drag-target-above {
          border-top: 15px solid #ccf;
        }

        &.drag-target-below {
          border-bottom: 15px solid #ccf;
        }

        &.drag-target {
          background-color: #ccf;
        }

        &.otp-warning {
          border-color: $otp-warning;
        }

        &.otp-danger {
          border-color: $otp-danger;
        }
      }

      .lt-cell {
        padding: 3px 5px 2px 2px; // same here: we just want the top cell to have padding
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 2px;
        &.priorityId {
          display: none;
        }

        &:focus-within {
          background-color: $table-cell-selected-color;
        }

        .lt-cell {
          padding: 0 0 0 0;
        }
      }

      .lt-body-wrap {
        margin-top: 3px;
      }

      .lt-body {
        color: $table-body-text;
        font-weight: 500;
        margin-top: 3px;
      }

      .is-sorted {
        .lt-sort-icon {
          visibility: visible;
        }
      }

      .lt-sort-icon {
        float: none;
        margin-left: auto;
        margin-right: 0;
        padding-left: 2px;
        visibility: hidden;
        line-height: 20px;
      }
    }

    .cell-text-extension {
      background-color: transparent;
      border: 0;
      padding: 0;
      color: #657175;
      font-weight: 300;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .tomtom-map {
    margin-top: $widget-border-width;
    margin-left: $widget-border-width;
    width: calc(100% - #{2 * $widget-border-width});
    height: calc(100% - #{2 * $widget-border-width});
    overflow: hidden;
    cursor: grab;
  }

  // prevent "invisible" trigger from taking up space in layout
  & > .ember-basic-dropdown-trigger {
    display: none;
  }

  .playback-table-header-column {
    display: flex;

    .column-label {
      flex-basis: 30px;
      flex-grow: 1;
      flex-shrink: 1;
      line-height: 23px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .column-controls {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 12px;
      padding-top: 4px;
      white-space: nowrap;
    }
  }

  .test-playback-widget {
    height: 1000px !important;
  }

  .leaflet-tooltip-pane {
    display: none;
  }

  .distance-icon {
    display: none;
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background: url(/map-icons/icon-distance.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  .pinpoint-cursor {
    cursor: url("/map-icons/icon-pinpoint.svg") 8 21, auto !important;
  }
  .removeCursor {
    cursor: none;
  }

  .icon-block {
    vertical-align: text-top;
  }

  .playback .map-widget .map-widget-header {
    position: absolute;
    top: -11px;
    width: 100%;
    z-index: 999;
  }

  .playback .leaflet-top {
    top: 30px;
  }

  .playback-widget-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    // height: 26px;
    margin-right: $column-title-margin;
    margin-left: auto; // helpful if we need to change .column-widget-header's justify-content attribute
    cursor: default;

    .playback-widget-button {
      flex: 0 0 30px;
      padding: 0px;
      margin-right: 8px;
      font-size: 18px;
      display: inline-block;
      background-color: inherit;
      border: none;
      color: $widget-button-color;
      opacity: 0.5;
      cursor: pointer;
      outline: none;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: $button-link-color;
        transition: color 0.2s;
        opacity: 1;
      }

      &.disabled {
        opacity: 0.2;
        pointer-events: none;

        &:hover {
          color: $widget-button-color;
          transition: none;
          opacity: 0.2;
        }
      }

      &:focus {
        box-shadow: 0 0 1px 1px $table-cell-selected-color;
        border-radius: 4px;
        opacity: 1;
      }
    }
    .active-button {
      color: $font-color;
      transition: color 0.2s;
    }

    .map-widget-controls-divider {
      flex: 0 0 1px;
      height: 20px;
      margin: 0 8px 0 0;

      background-color: $widget-button-color;
      opacity: 0.5;
    }

    .reset-button {
      opacity: unset;
      color: $button-link-color;
    }

    // the search box needs a little extra margin for focus border
    .column-widget-search-box {
      flex-grow: 1;
      flex-shrink: 0;
      height: $widget-header-height - (2 * $widget-header-padding) - 2px;
      margin: 1px 2px 1px $column-title-margin;
      padding: 3px 5px 3px 5px;
      max-width: 20em;
      border-radius: 5px;
      font-size: $widget-input-font-size;
      background-color: transparent;
      border: none;
      outline: none;
    }

    .column-widget-button {
      padding: 2px 0px;
      margin-right: 8px;
      display: inline-block;
      margin-top: -2px;
      font-size: 18px;
      background-color: inherit;
      border: none;
      color: $icon-light-color;
      opacity: 0.5;
      cursor: pointer;
      outline: none;

      &:last-child {
        margin-right: 0;
      }
      // height: 25px;

      &:focus {
        box-shadow: 0 0 1px 1px $table-cell-selected-color;
        border-radius: 4px;
        opacity: 1;
      }

      &:hover {
        color: dds("700");
        transition: color 0.2s;
        opacity: 1;
      }
    }
    .active-button {
      color: $icon-light-highlighted-color;
      transition: color 0.2s;
    }

    .column-widget-button {
      border-radius: 4px;
    }
    .playback-widget .flex-body-left {
      width: 253px;
    }
  }
}
.address-drop-down{
  width: 179px !important;
  line-height: 20px !important;
}