input:disabled {
  background-color: rgba(230, 231, 235, .8);
}

.booking-dashboard {
  background-color: #e6e7eb;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: .5em;
  padding-left: 1em;
  box-shadow: none;
  .booking-wrapper:nth-child(1){
    .column-widget .column-widget-body {
      flex-basis: 163px;
    }
  }

  .booking-wrapper:nth-child(2){
    .column-widget {
      .column-widget-body {
        flex-basis: 163px;
        .ember-light-table {
          width: fit-content !important;
          padding-left: 15px;
          .lt-head {
            th:first-child{
              padding: 3px 5px 2px 4px;
              .lt-column-resizer {
                background: #a6acaf !important;
                width: 2px !important;
              }
            }
          }
        }
      }
    }
  }
  .booking-wrapper:nth-child(3){
    .column-widget .column-widget-body {
      flex-basis: 163px;
      .ember-light-table {
        width: fit-content !important;
        padding-left: 15px;
        .lt-head {
          th:first-child{
            padding: 3px 5px 2px 4px;
            .lt-column-resizer {
              background: #a6acaf !important;
              width: 2px !important;
            }
          }
        }
      }
    }
  }
  .booking-wrapper:nth-child(4){
    .column-widget .column-widget-body {
      flex-basis: 163px;
      .ember-light-table {
        width: fit-content !important;
        padding-left: 15px;
        .lt-head {
          th:first-child{
            padding: 3px 5px 2px 4px;
            .lt-column-resizer {
              background: #a6acaf !important;
              width: 2px !important;
            }
          }
        }
        .lt-head th:last-child .lt-column-resizer{
          display: none;
        }
      }
    }
  }
  .booking-wrapper {
    border-radius: 7px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 4px 0px #b3b3b3;
    -moz-box-shadow: 0px 0px 4px 0px #b3b3b3;
    //  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
    box-shadow: 0px 0px 4px 0px #b3b3b3;
    margin-left: 1.7em;
    // background-color: #e6e7eb;
    .active-dot {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 4px;
      background-color: #73b544;
    }
    .inactive-dot {
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-right: 4px;
      border-radius: 50%;
      background-color: #a51621;
    }
    .Grid {
      border: 1px solid #e0e0e0;
    }
    .Cell {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border: none;
      // border-right: 1px solid #e0e0e0;
      // border-bottom: 1px solid #e0e0e0;
      outline: none;
      background: white;
    }
    .HeaderCell {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      // border: 1px solid #e0e0e0;
      background-color: #f5f5f7;
      font-weight: bold;
    }
    .FocusedCell {
      background-color: #e0e0e0;
      font-weight: bold;
    }
    .Radio {
      margin-left: 0.5rem;
    }
    .checkboxLabel {
      display: flex;
      align-items: center;
    }
    .checkbox {
      margin-right: 5px;
    }
    .widget-container {
    }
    .context-menu-cell {
      background-color: #c4f7c4;
    }
    .even-cell {
      background-color: #f2f2f2;
    }
    .odd-cell {
      background-color: white;
    }
    // Related to Resize Column header
    .DragHandle {
      flex: 0 0 16px;
      z-index: 2;
      cursor: col-resize;
      color: black;
    }
    .DragHandle:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    .DragHandleActive,
    .DragHandleActive:hover {
      color: #0b6fcc;
      z-index: 3;
    }
    .DragHandleIcon {
      flex: 0 0 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    // Related to Context Menu
    .menu {
      width: 150px;
      z-index: 1000;
      /* background-color:rgb(140, 226, 144); */
      background-color: lightgray;
      box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.2);
      transition: 0.2s display ease-in;
      z-index: 1000;
      border-radius: 5px;
      cursor: pointer;
    }
    .menu .menu-options {
      list-style: none;
      padding: 10px 0;
    }
    .menu-option {
      font-weight: 500;
      font-size: 15px;
      padding: 10px 40px 10px 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
    .menu-option:hover {
      background: rgba(0, 0, 0, 0.1);
    }
    .cell-checkbox {
      position: absolute;
      left: 2px;
      cursor: pointer
    }
    //
    .nb-container {
      background-color: #e6e7eb;
      height: 100%;
      width: 100%;
      // min-width: 1200px;
    }
    .cb-main {
      width: 96%;
      // min-width: 1375px;
      margin: 0 auto;
      padding: 0 10px;
      color: #555555;
    }
    .sp-widget {
      -webkit-box-shadow: 0px 0px 4px 0px #b3b3b3;
      -moz-box-shadow: 0px 0px 4px 0px #b3b3b3;
      box-shadow: 0px 0px 4px 0px #b3b3b3;
      background-color: white;
      border-radius: 7px;
      width: 100%;
      // height: 400px;
    }
    .sp-header {
      -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      -moz-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    .sp-heading {
      padding: 5px 15px;
      font-weight: 500;
      font-size: 18px;
      background-color: #f6f6f6;
      border-radius: 7px 7px 0 0;
      color: #5d5d5d;
    }
    .sp-body {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      .column-widget {
        .column-widget-body {
          .ember-light-table {
            width: fit-content !important;
            .lt-head {
              th {
                padding-left: 10px;
                .lt-column-resizer {
                  background: #a6acaf !important;
                  width: 2px !important;
                }
              }
            }
          }
        }
      }
    }
    .sp-search-section {
      color: #333;
      border-radius: 3px;
    }
    .search-sec {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px,auto));
      grid-gap: .5em;
      font-weight: bold;
      margin-bottom: 15px;
      font-size: 14px;
      color: #5d5d5d;
    }
    .fld-sec {
      input {
        width: 10em;
        font-weight: 500;
        color: #303e44;
        border: 1px solid #bab6b6;
        border-radius: 2px;
        padding-left: 0.5em;
        font-size: 13px;
        margin-right: 0;
        vertical-align: middle;
      }
      input[type="checkbox"] {
        width: fit-content;
      }
    }
    .activeOnlyCheckboxLabel {
      height: 0;
    }
    .activeOnlyCheckboxLabel:focus-within {
      outline:  #63c915  auto 0.5px !important;
    }
    .fld-sec input[type="text"]:focus {
      outline:  #63c915  auto 0.5px !important;
    }

    .fld-sec input::placeholder {
      font-weight: 500;
      color: #afb2b3;
      font-size: 12px;
    }
    .wider input{
      width:120px;
    }
    .elm {
      margin-right:0;
      margin-left:0;
    }
    .fld-sec button {
      line-height: 5px;
      height: 23px;
    }
    .btn:focus {
      outline: 0 !important;
      outline-offset: 0 !important;
      background-image: none !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
    .btn-secondary {
      color: #5b5b5b;
      background-color: #dfdfdf;
      // #d9d9d9
      border-color: #bab6b6;
      min-width: 90px;
      line-height: 15px;
      font-weight: 500;
      border-radius: 2px;
    }
    .btun-sec {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .v-btn {
      margin: 10px;
    }
    .book-trip {
      margin: 10px 0 10px;
      display: flex;
      justify-content: flex-end;
    }
    .btn:focus {
      outline:  #63c915  auto 0.5px !important;
    }
    .seperator-fname input{
      width: 100px;
    }
   
    .seperator-fname::after {
      content: "";
      border-left: 1px solid black;
      height: 20px;
      margin-left: 1em;
    }
    .seperator-active-only::after {
      content: "";
      border-left: 1px solid black;
      height: 20px;
      margin-left: 1em;
    }
  }

  .my-cust-sec-window {
    .secondary-window {
      .wigt-tbl {
        background-color: white;
        border-radius: 3px;
        line-height: 5px;
      }
      padding-bottom: 5px;
      .secondary-window-modal {
        font-weight: 500;
        min-width: 37vw;
        .secondary-window-body {
          padding-bottom: 5px;
          overflow-y: auto;
        }
        .secondary-window-header {
          text-align: center;
          padding-top: 10px;
          padding-bottom: 10px;
          display: block;
          font-size: 20px;
          div {
            display: none
          }
          button {
            float: right;
          }
        }
        .dashboard-save-as-controls {
          text-align: right;
          width: 91%;
        }

        .btn-pri {
          justify-self: flex-end;
          border-radius: 5px;
          border: 1px solid white;
          display: inline-block;
          cursor: pointer;
          color: white;
          font-size: 14px;
          font-weight: 400;
          height: 26px;
          width: 125px;
          padding: 0px 38px;
          text-decoration: none;
          background-image: linear-gradient(#334247, #171f22);
        }
      }
    }
  }
}
