.form-components-datetimeflatpickr {
    flex: 1 1 auto;
    &:disabled {
      background-color: transparent;
    }

    &.error {
      input {
        border: 2px solid $otp-danger !important;
      }
    }
  }