.vehicle-2col-lookup{
  .row {
    margin: 0 0 0.5rem 0;
    .col-auto-code{
      flex: 0 0 7.5%;
    }
    .col-5-description{
      flex: 0 0 48.3333%;
    }
  }
}