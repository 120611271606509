.form-component-input-mask-layout {
  font-size: $font-size-sm-dds;

  &.error {
    input {
      border: 2px solid $otp-danger !important;
    }
  }

  input {
    font-size: $font-size-xsm-dds;
    font-weight: $font-weight-light-dds;
    padding: 3px 7px 0 7px;

    &:disabled {
      cursor: not-allowed;
      color: #aeaeae;
      background: transparent;
  
      &:hover {
        color: #aeaeae;
      }
    }
  }

  .fare-field {
    background-color: white !important;
  }
}
