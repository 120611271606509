// Example of the 7-1 technique used by many and how its ordered in a main scss file
// we don't necessarily have to follow this structure, but something similar we should do for our scss project structure

/*

sass/
|
|– abstracts/
|   |– _variables.scss    # Sass Variables
|   |– _functions.scss    # Sass Functions
|   |– _mixins.scss       # Sass Mixins
|   |– _placeholders.scss # Sass Placeholders
|
|– base/
|   |– _reset.scss        # Reset/normalize
|   |– _typography.scss   # Typography rules
|   …                     # Etc.
|
|– components/
|   |– _buttons.scss      # Buttons
|   |– _carousel.scss     # Carousel
|   |– _cover.scss        # Cover
|   |– _dropdown.scss     # Dropdown
|   …                     # Etc.
|
|– layout/
(specific layouts like a navigation might have left, center, right or left, right might not be needed as we build ui components and less use of actual html templating)
|   |– _navigation.scss   # Navigation
|   |– _grid.scss         # Grid system
|   |– _header.scss       # Header
|   |– _footer.scss       # Footer
|   |– _sidebar.scss      # Sidebar
|   |– _forms.scss        # Forms
|   …                     # Etc.
|
|– pages/ (ours is called routes)
|   |– _home.scss         # Home specific styles
|   |– _contact.scss      # Contact specific styles
|   …                     # Etc.
|
|– themes/ (not necessary at this time)
|   |– _theme.scss        # Default theme
|   |– _admin.scss        # Admin theme
|   …                     # Etc.
|
|– vendors/
|   |– _bootstrap.scss    # Bootstrap
|   |– _jquery-ui.scss    # jQuery UI
|   …                     # Etc.
|
`– main.scss              # Main Sass file
*/

// must load these before `ember-bootstrap/bootstrap`
@import 'overrides/bootstrap-overrides.scss';
// vendor
@import 'ember-bootstrap/bootstrap';
@import "ember-modal-dialog/ember-modal-structure";
@import "ember-modal-dialog/ember-modal-appearance";
@import 'ember-power-calendar';
@import 'ember-power-select';
// global
@import 'abstracts/variables.scss';
@import 'abstracts/functions.scss';
@import 'abstracts/mixins.scss';
@import 'abstracts/media-query-mixins.scss';
@import 'base/fonts.scss';
@import 'base/reset.scss';
@import 'base/utility.scss';
// custom css
@import 'base/base.scss';
// override ember-power-calendar
@import 'overrides/ember-power-calendar-overrides.scss';
@import 'overrides/ember-power-selecter.scss';
@import 'overrides/ember-tooltip-override.scss';
// general components
@import 'components/bootstrap-progress-bar.scss';
@import 'components/buttons.scss'; // we should touch this up to make a more generic css for buttons
@import 'components/checkbox-switch.scss';
@import 'components/datepickr-row.scss';
@import 'components/dropdown-provider.scss';
@import 'components/permissions-dropdown-provider.scss';
@import 'components/edit-sidebar.scss';
@import 'components/global-spinner.scss';
@import 'components/header-nav.scss';
@import 'components/maximized-widget.scss';
@import 'components/modal-dialog.scss'; // generic css for modals, specific components then can enhance this css more
@import 'components/modal-sidebar.scss';
@import 'components/notification-bar.scss';
@import 'components/tile-dashboard.scss';
@import 'components/timeline-picker.scss';
@import 'components/toggle-switch.scss';
@import 'components/road-supervisor-dashboard-info.scss';
@import 'components/workspace-navbar.scss';
@import 'components/menu-navbar.scss';
@import 'components/card-item.scss';
@import 'components/help-link.scss';
// generic components
@import 'components/generic-components/availabilities.scss';
@import 'components/generic-components/content-overlay.scss';
@import 'components/generic-components/datetimeflatpickr.scss';
@import 'components/generic-components/generic-panel.scss';
@import 'components/generic-components/number-format.scss';
@import 'components/generic-components/progress-bar.scss';
@import 'components/generic-components/ring-loader.scss';
@import 'components/generic-components/tab-group.scss';
@import 'components/generic-components/text-area';
@import 'components/generic-components/vertical-menu.scss';
// generic table component
@import 'components/generic-components/table/table-component.scss';
// form components
@import 'components/form-components/text.scss';
@import 'components/form-components/date.scss';
@import 'components/form-components/text-area.scss';
@import 'components/form-components/permission.scss';
@import 'components/form-components/locations.scss';
@import 'components/form-components/travel-need.scss';
@import 'components/form-components/eligibilities.scss';
@import 'components/form-components/availabilities.scss';
@import 'components/form-components/subscriptions.scss';
@import 'components/form-components/address-search.scss';
@import 'components/form-components/on-board-trips.scss';
@import 'components/form-components/activity-log.scss';
@import 'components/form-components/breakdown-place.scss';
@import 'components/form-components/assign-vehicle.scss';
@import 'components/form-components/input-mask-layout.scss';
@import 'components/form-components/vehicle-selection.scss';
@import 'components/buttons.scss';
@import 'components/toggle-switch.scss';
@import 'components/dropdown-provider.scss';
// modal components
@import 'components/modal-components/about-adept.scss';
@import 'components/modal-components/action-modal.scss';
@import 'components/modal-components/capacity-modal.scss';
@import 'components/modal-components/dashboard-picker.scss';
@import 'components/modal-components/dashboard-save-as.scss';
@import 'components/modal-components/driver-break-master';
@import 'components/modal-components/reoptimize/reoptimize-modal.scss';
@import 'components/modal-components/reoptimize/operation-list.scss';
@import 'components/modal-components/reoptimize/operation-view.scss';
@import 'components/modal-components/reoptimize/operation-views/trim-slack.scss';
@import 'components/modal-components/reoptimize/operation-views/reoptimize.scss';
@import 'components/modal-components/reoptimize/operation-views/open-zones.scss';
@import 'components/modal-components/reoptimize/operation-views/release-routes.scss';
@import 'components/modal-components/road-supervisor-modal.scss';
@import 'components/modal-components/schedule-generate.scss';
@import 'components/modal-components/search-modal.scss';
@import 'components/modal-components/get-special-permission.scss';
@import 'components/modal-components/schedule-metrics.scss';
@import 'components/modal-components/user-profile/user-profile.scss';
@import 'components/modal-components/user-profile/views/api-call-record-table.scss';
@import 'components/modal-components/user-profile/views/change-credentials.scss';
@import 'components/modal-components/user-profile/views/optimization-settings.scss';
@import 'components/modal-components/optimize-schedule.scss';

// side drawer components
@import 'components/side-drawer-modal.scss';
@import 'components/g-side-drawer.scss';
@import 'components/side-drawer.scss';
@import 'components/side-drawer/activity-log.scss';
@import 'components/side-drawer/add-break.scss';
@import 'components/side-drawer/add-edit-schedule-route.scss';
@import 'components/side-drawer/add-new-route.scss';
@import 'components/side-drawer/add-widget.scss';
@import 'components/side-drawer/assign-vehicle-driver-to-route.scss';
@import 'components/side-drawer/bulk-trip-transfer.scss';
@import 'components/side-drawer/blockout-alternative-addresses.scss';
@import 'components/side-drawer/cancel-trip-form.scss';
@import 'components/side-drawer/compose-role';
@import 'components/side-drawer/filter-settings.scss';
@import 'components/side-drawer/map-filter.scss';
@import 'components/side-drawer/map-legend-filter.scss';
@import 'components/side-drawer/metrics-filter.scss';
@import 'components/side-drawer/no-show-form.scss';
@import 'components/side-drawer/perform-stop.scss';
@import 'components/side-drawer/rescue-route.scss';
@import 'components/side-drawer/route-activity-log.scss';
@import 'components/side-drawer/trip-activity-log.scss';
@import 'components/side-drawer/trip-transfer.scss';
@import 'components/side-drawer/vehicle-activity-log.scss';
@import 'components/side-drawer/edit-trip.scss';
@import 'components/side-drawer/add-break.scss';
@import 'components/side-drawer/new-booking-form.scss';
@import 'components/side-drawer/bulk-trip-transfer.scss';
@import 'components/side-drawer/playback-filter-settings.scss';
@import 'components/side-drawer/cancel-booking-form.scss';
@import 'components/side-drawer/booking-activity-log.scss';
@import 'components/side-drawer/subscription-activity-log.scss';
@import 'components/side-drawer/ada-eligibility.scss';

// reconcile
@import 'components/side-drawer/reconcile-dates.scss';
@import 'components/widgets/reconcile-column-editor.scss';
@import 'components/reconcile-search-passenger.scss';
@import 'components/widgets/reconcile-unscheduled-trips.scss';
@import 'components/side-drawer/reconcile-add-new-trip.scss';
@import 'components/side-drawer/reconcile-schedule-unscheduled-trips.scss';
// side-drawer system config
@import 'components/side-drawer/system-config/system-config.scss';
@import 'components/side-drawer/system-config/header.scss';
@import 'components/side-drawer/system-config/route-master.scss';
@import 'components/side-drawer/system-config/contract-management.scss';
@import 'components/side-drawer/system-config/providers.scss';
@import 'components/side-drawer/system-config/places.scss';
@import 'components/side-drawer/system-config/no-show-reason-categories.scss';
@import 'components/side-drawer/system-config/speed-region-groups.scss';
@import 'components/side-drawer/system-config/ada-boundaries.scss';

// widget components
@import 'components/widgets/base-widget.scss';
@import 'components/widgets/dummy-widget.scss';
@import 'components/widgets/column-widget.scss';
@import 'components/widgets/form-widget.scss';
@import 'components/widgets/map-widget/widget.scss';
@import 'components/widgets/map-widget/icons.scss';
@import 'components/widgets/map-widget/marker-cluster.scss';
@import 'components/widgets/navigation-widget.scss';
@import 'components/widgets/playback-widget.scss';
@import 'components/widgets/box-widget.scss';
@import 'components/widgets/cancel-type-form.scss';
@import 'components/widgets/analytics-widget.scss';
@import 'components/widgets/metrics-column-widget.scss';
@import 'components/widgets/donut-chart-widget.scss';
@import 'components/widgets/bar-chart-widget.scss';
@import 'components/widgets/gauge-widget.scss';
@import 'components/widgets/tree-widget.scss';
@import 'components/widgets/add-replacement-route-form.scss';
@import 'components/widgets/booking-widget.scss';
@import 'components/widgets/ember-react-widget.scss';
@import 'components/widgets/ember-react-playback.scss';
@import 'components/widgets/route-form-widget.scss';
@import 'components/widgets/route-widget.scss';

//fleet-manager-widget
@import 'components/widgets/fleet-manager/widget.scss';
@import 'components/widgets/fleet-manager/views/vehicle-table.scss';
@import 'components/widgets/fleet-manager/views/vehicle-details.scss';


// vehicle-driver widget
@import 'components/widgets/vehicle-driver-management/widget.scss';

// reportlist widget
@import 'components/widgets/reportlist-widget.scss';

// edit views driver
@import 'components/widgets/vehicle-driver-management/views/driver.scss';
@import 'components/widgets/vehicle-driver-management/views/driver-review.scss';
@import 'components/widgets/vehicle-driver-management/views/driver-health.scss';
@import 'components/widgets/vehicle-driver-management/views/driver-provider.scss';
@import 'components/widgets/vehicle-driver-management/views/driver-suspension.scss';
@import 'components/widgets/vehicle-driver-management/views/driver-violation.scss';
@import 'components/widgets/vehicle-driver-management/views/driver-log.scss';
@import 'components/widgets/vehicle-driver-management/views/driver-accident.scss';
// edit views vehicle
@import 'components/widgets/vehicle-driver-management/views/maintenance.scss';
@import 'components/widgets/vehicle-driver-management/views/dot-inspection.scss';
@import 'components/widgets/vehicle-driver-management/views/road-call.scss';
@import 'components/widgets/vehicle-driver-management/views/equipment.scss';
@import 'components/widgets/vehicle-driver-management/views/vehicle.scss';
// lookup views
@import 'components/widgets/vehicle-driver-management/lookup/driver-lookup.scss';
@import 'components/widgets/vehicle-driver-management/lookup/vehicle-lookup.scss';
@import 'components/widgets/vehicle-driver-management/lookup/vehicle-2col-lookup.scss';
@import 'components/widgets/vehicle-driver-management/lookup/vehicle-capacity-type.scss';
@import 'components/widgets/vehicle-driver-management/lookup/vehicle-type.scss';
@import 'components/widgets/reconcile-widget.scss';
// Reconcile search mode component
@import 'components/widgets/reconcile-search-mode-pop-up.scss';
// timeline components
@import 'components/timeline/date-picker.scss';
@import 'components/timeline/time-picker.scss';
@import 'components/timeline/relative-time-picker.scss';
// light-table components
@import 'components/table/cells/base-cell.scss';
@import 'components/table/cells/check-box.scss';
@import 'components/table/cells/radio-button.scss';
@import 'components/table/cells/user-editable.scss';
@import 'components/table/cells/user-editable-enum.scss';
@import 'components/table/cells/text-extension.scss';
@import 'components/table/cells/trip-eta.scss';
@import 'components/table/cells/trip-cell-img-icon.scss';
@import 'components/table/columns/base-column.scss';
@import 'components/table/columns/check-box.scss';
@import 'components/table/columns/filter-capable.scss';
@import 'components/table/rows/base-row.scss';
@import 'components/table/rows/action-row.scss';
@import 'components/table/rows/colored-row.scss';
// routes
@import 'routes/application.scss';
@import 'routes/dashboard.scss';
@import 'routes/register.scss';
@import 'routes/login.scss';
@import 'routes/modals/settings.scss';
@import 'routes/modals/edit-widget-modal.scss';
@import 'routes/modals/text-extension-modal.scss';
@import 'routes/forgot.scss';
@import 'routes/reset.scss';
@import 'routes/change-password.scss';
@import 'routes/change-password-confirmation.scss';
@import 'routes/require-new-password.scss';
@import 'routes/rider-management.scss';
@import 'routes/reports.scss';
@import 'routes/landing-page.scss';
@import 'routes/sandbox-setup.scss';
