#landing-page {

  .card-container {
      display:flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 150px auto;
  }

}
