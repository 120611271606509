.datetimeflatpickr-wrapper {
  flex: 1 1;
  display: flex;
  align-items: center;
  border: 1px solid #cdd4d6;
  border-radius: 4px;
  background-color: #ffffff;
  line-height: 1.75;
  overflow-x: hidden;
  text-overflow: ellipsis;

  &.error {
    border: 2px solid $otp-danger !important;
  }

  &.focus {
    background-color: #fff;
    border-color: #bbda9b;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(124, 179, 66, 0.25);
  }

  &.disabled {
    background: transparent;
  }

  .flatpickr-input {
    cursor: pointer;
    border: 0;
    flex: 1 1 auto;

    &:disabled {
      cursor: not-allowed;
      color: #aeaeae;
      background: transparent;

      &:hover {
        color: #aeaeae;
      }
    }
  }

  a {
    margin-right: 0.5rem;
    cursor: pointer;
    color: $dark-dds;

    &:hover {
      color: $dark-dds;
    }

    &.input-button {
      &.is-open {
        pointer-events: none !important;
      }
    }

    &.clear-button {
      color: #aaaaaa;
      &:hover {
        color: $dds-primary;
      }
    }
  }
}
