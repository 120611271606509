.form-widget {
  &.breakdown-replacement-form-widget {
    .cell-label {
      min-width: 130px;
    }

    .form-components-datetimeflatpickr .flatpickr-input {
      width: 100%;
    }

    .form-widget-body {
      .section-table {
        .generic-panel {
          .panel-header {
            flex: 0 0 35px;
            justify-content: left;

            .fa {
              flex: 0 0 21px;
            }
          }
        }
      }
    }
  }
}
