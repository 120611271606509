.table-filter-capable-column {
  .column-label {
    flex-basis: 30px;
    flex-grow: 1;
    flex-shrink: 1;
    line-height: 23px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

  .column-controls {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 12px;

    padding-right: 4px;

    white-space: nowrap;
  }
}
