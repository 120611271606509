.open-zones {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 538px;
  height: 534px;
  border: 1px solid #CCCED0;
  border-radius: 4px;
  background-color: #F5F5F7;

  .open-zones-main {
    padding-top: 10px;
    padding-left: 10px;

    .zones-section {
      margin-top: 12px;
  
      .open-zones-table-div {
        height: 362px;
        overflow-y: scroll;
        border: 1px solid #dddddd;
  
        .open-zones-table {
          border: 1px solid #dddddd;
          border-collapse: collapse;
          width: 100%;
  
          td, th {
            text-align: left;
            padding: 3px 0px 3px 5px;
          }
          
          tr:nth-child(odd) {
            background-color: white;
          }
        }
      }
    }
  }
  
  button.open-zones-button {
    margin-right: 11px;
    margin-top: 5px;
  }

  .body-wrapper {
    display: flex;
    flex-direction: column;

    h3 {
      font-family: "Avenir Next";
      font-size: 14px;
      font-weight: 600;
    }
  
    .inputs {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      font-size: 12px;
      font-weight: 500;
    }
  }
}