.ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center {
  .tooltip-modal-styles-overlay {
        padding: 0;
        border-radius: 1;
        z-index: zindex(action-modal);
        outline: none;
        &.ember-modal-overlay.translucent {
          background-color: rgba(38,50,56,0.2);
        }

      .ember-modal-dialog {
          background-color: transparent;
          box-shadow: none;
          transform: none !important;
          left: calc(50% - 200px);
          top: calc(50% - 90px);
          z-index: zindex(action-modal);

        .tooltip-modal {
          width: 400px;
          color:  #303E44;
          background: white;
          border-radius: 8px;
          box-shadow: 0px 2px 5px rgba(38, 50, 56, 0.50);
          &.day-action-modal {
            width: auto;
            max-width: 500px;
          }

          .tooltip-modal-header {
            width: 100%;
            height: 35px;
            background-image: linear-gradient( #334247, #171f22);
            font-size: medium;
            text-align: center;
            line-height: 2;
            padding-left: 6px;
            border-radius: 10px 10px 0px 0px;

            button {
              background-color: transparent;
              border: 0.50px #586368 solid;
              outline: none;
              float: right;
              padding-right: 15px;
              color: #ffffff;

              &:hover {
                color: $dds-primary;
              }
            }
          }

          .tooltip-modal-body {
            padding: 20px 18px 18px 18px;
            font-size: $font-size-sm-dds;
            font-weight: $font-weight-base-dds;

            .tooltip-text {
              display: flex;
              margin-bottom: 0.5rem;
              .tooltip-tip {
                margin-left: 0.5rem;
              }
            }

            .tooltip-sub-text {
              .tooltip-sub-tip {
                display: list-item;
                margin-left: 1.5rem;
              }
            }

            .error-container {
              display: flex;
              flex-direction: column;

              .tooltip-error-mainMessage {
                margin-bottom: 10px;
              }
            }

            .tooltip-modal-button.days-btns {
              display: flex;
              justify-content: flex-start;
              margin-top: 1rem;
              flex-wrap: wrap;
              button {
                margin-bottom: 5px;
              }
            }

            .tooltip-modal-button {
              display: flex;
              justify-content: flex-end;
              margin-top: 2rem;

              .btn-outline-secondary, .btn-outline-primary {
                color:  #586368;
                box-shadow: 0px 2px 5px rgba(38, 50, 56, 0.50);
                line-height: 1.0;
                padding: 5px 20px;
                margin-right: 10px;
              }

              .btn-outline-secondary:hover, .btn-outline-primary:hover {
                background: $dds-primary-gradient;
                color: white;
                box-shadow: none;
              }

              .tooltip-running {
                min-width: 98px;
              }
            }
          }
        }
      }
  }
}
