.driver-provider-view {
  .driver-provider-table {
    height: 200px;
  }

  input,
  textarea {
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
    border-right: 1px solid #aaaaaa;
    border-left: 1px solid #aaaaaa;
    border-radius: 4px;
    background-color: #ffffff;
    line-height: 1.75;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  label {
    margin: 0;
  }

  .form-panel-wrapper {
    display: flex;
    margin-top: 0.5rem;
    min-height: 150px;

    .col {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    button {
      @include button();
      height: 23px;
      line-height: 1rem;
      i {
        font-size: $font-size-xsm-dds;
      }
      &:hover {
        i {
          color: $link-primary;
        }
      }
      &:disabled {
        i {
          color: inherit;
        }
      }
    }
  }

  .generic-panel {

    .row {
      margin-bottom: 0.5rem;
    }

    .panel-body {
      font-size: 12px;
      font-weight: 600;

      .container {
        padding: 0 4rem 0 4rem;

        .input-group {
          width: 80%;
          align-items: center;
        }

        .form-components-text {
          width: 80%;
        }

        .form-components-text-area  {
          width: 80%;
        }

        .form-component-input-mask-layout  {
          width: 80%;
        }

        .ember-power-select-trigger {
          height: 25px;
          width: 80%;
          &.error {
            border: 2px solid $otp-danger !important;
          }
        }
        .col{
          &.error {
            .ember-power-select-trigger {
              border: 2px solid $otp-danger !important; 
            }
          }
        }
        .datetimeflatpickr-wrapper {
          width: 80%;
          input {
            width: 100%;
            border: 0;
          }
          &.error {
            border: 2px solid $otp-danger !important;
          }
        }

        .ember-text-field-holder {
          width: 80%;
        }
      }
    }
  }
}
