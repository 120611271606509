// form-widget font color
$form-widget-font-color: $dds-primary-grey;
// form-widget button colors
$form-widget-button-color: dds-greys("200");
$form-widget-button-outline: $form-widget-button-color;
$form-widget-button-disabled-color: rgb(218, 218, 218);
// form-widget table colors
$form-widget-table-background-color: $background-lighter;
$form-widget-table-border-color: #ccced0;
// form-widget title font style
$form-widget-title-font-size: 15px;

.form-widget {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0px;

  color: $form-widget-font-color;
  font-family: $ui-font;

  display: flex;
  flex-direction: column;

  button {
    outline: none;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(111, 121, 129, 0.5);
    }
  }

  .ember-power-select-trigger:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }

  .flatpickr-input:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }

  .button-cell {
    padding: 5px;
    text-align: right;
    width: 20px;
    height: 20px;
  }

  .form-button-primary {
    font-size: 14px;
    font-weight: 500;
    border: 2px #7cb342 solid;
    background-color: #7cb342;
    color: white;
    border-radius: 5px;
    padding: 5px 25px 3px 25px;
    &:hover {
      background-color: dds("700");
      color: white;
    }
  }

  .form-button-outline {
    font-size: 14px;
    font-weight: 500;
    color: #7cb342;
    border: 2px #7cb342 solid;
    border-radius: 5px;
    padding: 5px 25px 3px 25px;

    &:hover {
      background-color: dds("700");
      color: white;
    }
  }

  .form-widget-header-wrapper {
    width: 100%;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    z-index: 5;

    .form-widget-header {
      padding: 0 13px;
      margin: 0;
      background-color: $form-widget-table-background-color;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .form-widget-title {
        margin: auto 0;
        font-size: $form-widget-title-font-size;
        font-weight: 600;
      }

      .form-widget-search {
        font-size: $widget-body-font-size;
        color: $input-color;
        background-color: $input-bg;
        background-clip: padding-box;
        border: $input-border-width solid $input-border-color;
        border-radius: 3px;
        outline: none;
        display: flex;
        margin-right: 3px;

        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 16px;

        .form-widget-search-input {
          font-size: 12px;
          height: 100%;
          flex-grow: 1;
          flex-shrink: 1;
          border: none;

          &::placeholder {
            font-style: italic;
          }

          span {
            margin-left: 0px;
          }
        }

        .form-widget-search-x {
          position: relative;
          padding: 0 2px;
          top: 1px;
          float: right;
          background-color: transparent;
          border: none;
          color: $form-widget-button-color;
          font-size: 1.3em;

          &:hover {
            color: $icon-light-highlighted-color;
          }
        }
      }

      .form-widget-controls {
        flex-grow: 0;
        flex-shrink: 0;
        line-height: 1.1;
        cursor: default;

        .form-widget-header-button {
          padding: 8px 0;
          margin-left: 12px;
          background-color: transparent;
          color: $form-widget-button-color;
          border: none;
          cursor: pointer;

          &:hover {
            transition: color 0.2;
            color: $dds-primary;
          }

          i {
            font-size: 1em;
          }

          .fa-check {
            font-size: 1.1em;
          }
          .fa-times {
            font-size: 1.1em;
          }

          .search-button {
            // padding-top: 6px;
          }
        }

        input {
        }
      }
    }

    .form-widget-subheader {
      margin: 5px 13px;

      .form-widget-readonly-section {
        .readonly-label {
          display: block;
          padding-right: 10px;
          white-space: nowrap;
          font-size: 12px;
          font-weight: 600;

          span {
            vertical-align: middle;
          }
        }
      }

      input {
        vertical-align: middle;
        margin-right: 2px;
      }

      .disabled {
        label {
          color: #bec1c3;
        }

        input[type="checkbox"] {
          color: #aeb2b5;
          background: black;
        }
      }

      .subheader-buttons {
        display: inline;
        position: relative;
        top: -3px;
        float: right;

        button {
          font-size: 14px;
          font-weight: 500;
          border: 2px $dds-primary solid;
          border-radius: 5px;
          padding: 5px 25px 3px 25px;
        }

        .header-button {
          background-color: $dds-primary;
          color: white;

          &:hover {
            background-color: dds("700");
          }
        }

        .header-button-outline {
          color: $dds-primary;

          &:hover {
            background-color: dds("700");
            color: $background-dark-dds;
          }
        }
      }
    }
  }

  .form-widget-body {
    overflow: hidden;
    overflow-y: auto;
    flex: 1 1 auto;
    padding: 0px 12px 10px 12px;

    .subscriptions-table {
      border: none;
    }

    .panel-body {
      padding-left: 0.5rem;
    }

    .section-table {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0.5rem 0 0;
      .section-row, .field-body {
        display: flex;
        margin-bottom: 0.25rem;
        align-content: center;
        align-items: center;
      }

      .form-label {
        font-size: $widget-input-font-size;
        min-width: 155px;
        flex: 0 0 25%;

        @include tablet {
          font-size: $widget-input-mobile-font-size;
        }
      }

      .form-edit-cell {
        font-size: $widget-body-font-size;
        flex: 1 1 auto;

        .form-components-datetimeflatpickr {
          width: 100%;
          height: 22px;

          .flatpickr-input {
            height: 22px;
          }
        }
      }
    }
  }

  .btn {
    color: $form-widget-button-color;
    border-color: $form-widget-button-outline;
    float: right;
  }

  .btn-remove {
    border: none;
    border-color: transparent;
    background-color: transparent;
    float: right;
  }

  &.passenger-form-widget table {
    min-width: 99%;
  }

  &.new-trip-form-widget table {
    min-width: 99%;
  }

  .row-hidden {
    display: none;
  }
  .cell-header {
    h4 {
      font-size: 12px;
      font-weight: 600;
      padding: 2px 5px 2px 5px;
      margin-top: 8px;
      margin-bottom: 0px;
    }
  }

  .btn {
    // line-height: 0.5em;
    font-size: $widget-button-font-size;

    &.btn-outline-primary {
      color: white;
      background-color: $dds-primary;
      &:hover {
        color: white;
      }
    }

    &.btn-outline-secondary {
      color: $icon-light-highlighted-color;

      &:hover {
        color: white;
        background-color: dds("700");
      }
    }

    &.multitype-button {
      padding: 2px 5px;
    }

    &:disabled {
      color: $icon-light-color;
      border-color: $button-outline;
      background-color: $button-disabled-background;
      &:hover {
        color: $icon-light-color;
      }
    }
  }

  table {
    tr.header {
      user-select: none;
      cursor: pointer;
      vertical-align: middle;

      &.is-closed {
        .fa-sort-down {
          transform: rotate(-90deg);
          position: relative;
          left: 6px;
          bottom: 4px;
        }
      }
    }
  }

  /* Style inputs, select elements and textareas */
  input[type="text"],
  select,
  textarea {
    width: 100%;
    padding: 1px 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
    font-size: 12px;
  }

  .disabled {
    background: #dddddd;
  }

  /* Style the label to display next to the inputs */
  label {
    /*padding: 12px 12px 12px 0;*/
    display: inline-block;
    font-weight: bold;
    line-height: 8px;
    font-size: 14px;
  }

  /* Style the submit button */
  input[type="submit"] {
    background-color: #4caf50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
  }

  /* Style the container */

  .form-container {
    background-color: #f5f5f7;
    border: 1px solid #ccced0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin: 10px 10px 0px 10px;
    padding: 10px;

    .hint {
      text-align: right;
      color: #b8b8b8;
      float: left;
      width: 100%;
      font-size: 12px;
    }

    /* Floating column for inputs: 100% width */
    .col-100 {
      float: left;
      width: 100%;
      margin-top: 4px;
    }

    .form-row {
      margin-bottom: 8px;
    }

    /* Clear floats after the columns */
    .form-row:after {
      content: "";
      display: table;
      clear: both;
    }

    /* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
    @media screen and (max-width: 600px) {
      .col-25,
      .col-75,
      input[type="submit"] {
        width: 100%;
        margin-top: 0;
      }
    }
  }

  .m-b-0 {
    margin-bottom: 0px !important;
  }

  .m-t-0 {
    margin-top: 0px !important;
  }

  .m-t-15 {
    margin-top: 15px !important;
  }

  .draftMessages {
    padding-top: 10px;
    padding-bottom: 5px;

    .d-row {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;
      margin-bottom: 5px;

      &:first-child {
        cursor: pointer;
      }

      .arrow {
        height: 24px;
        width: 7px;
        margin-right: 3px;
      }

      .label {
        line-height: 24px;
        min-width: 90px;
        width: 100%;
        max-width: 90px;
        height: 20px;
        margin-left: 6px;
        margin-right: 4px;

        color: $font-color;
        font-family: $ui-font;
        font-size: 12px;
        font-weight: 800;
        line-height: 24px;
      }

      .value {
        box-sizing: border-box;
        min-height: 22px;
        min-width: 210px;
        max-width: 257px;
        width: 100%;
        border: 1px solid rgba(38, 50, 56, 0.2);
        border-radius: 2px;
        background-color: #f5f5f7;
        margin-left: 3px;
        margin-right: 3px;

        font-family: $ui-font;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;

        padding: 2px 4px 2px 4px;
      }

      .status {
        line-height: 24px;
        min-width: 20px;
        height: 20px;
        background-color: #dbdbdb;
        margin-left: 8px;
        border-radius: 50%;
        margin-top: 2px;

        .failed-icon {
          width: 100%;
          height: 100%;
          background-image: url("/icons/ic-msg-failed.svg");
        }

        .success-icon {
          width: 100%;
          height: 100%;
          background-image: url("/icons/ic-msg-success.svg");
        }
      }

      .error {
        margin-top: 22px;
        height: 24px;
        width: 200px;
        color: #a51621;
        font-family: $ui-font;
        font-size: 14px;
        font-weight: 800;
        line-height: 24px;
      }
    }
  }

  .btn {
    background-color: inherit;
    padding: 4px 24px 4px 24px;
    font-size: 14px;
    cursor: pointer;
    float: left;
    border-color: $form-widget-button-outline;
    margin: 4px;
    letter-spacing: 0.5px;
    font-weight: 500;
  }

  /* On mouse-over */
  .btn:hover {
    background: #eee;
  }

  .success {
    box-sizing: border-box;
    border: 1px solid #b4b4b4;
    border-radius: 2px;
    background: linear-gradient(180deg, #47a41b 0%, #2b6a0d 100%);
    box-shadow: 0 1px 2px 0 rgba(133, 133, 133, 0.5);
    color: #ffffff;
    background-repeat: no-repeat;
  }

  .success:hover {
    background-position: 0 0;
    background: #47a41b;
    -webkit-transition: background-position 1s ease-out;
    -moz-transition: background-position 1s ease-out;
    -o-transition: background-position 1s ease-out;
    transition: background-position 1s ease-out;
  }

  .info {
    color: dodgerblue;
  }
  .warning {
    color: orange;
  }
  .danger {
    color: red;
  }
  .default {
    box-sizing: border-box;
    border: 1px solid #b4b4b4;
    border-radius: 2px;
    background: linear-gradient(180deg, #f9f9f9 0%, #d1d1d1 100%);
    box-shadow: 0 1px 2px 0 rgba(133, 133, 133, 0.5);
    color: #363b42;
    color: #000;
  }

  &.routes-start-form-widget, &.routes-end-form-widget {
    .form-widget-body {
      .section-table {
        .generic-panel {
          .panel-header.is-collapsible {
            display: flex;
            padding: 4px;
            justify-content: flex-start;

            .panel-title {
              padding-left: 10px;
            }
          }
        }
      }
    }
  }

  &.routes-start-form-widget .cell-label {
    min-width: 127px;
  }
  &.routes-end-form-widget .cell-label {
    min-width: 127px;
  }

  input:read-only {
    background-color: #f5f5f7 !important;
  }

  .label {
    line-height: 12px;
  }

  .ellipsis {
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    width: 340px;
    white-space: nowrap;
    vertical-align: middle;
  }
}

.add-new-message .form-widget {
  overflow: auto !important;
}
