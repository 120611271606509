.is-touch-device .map-widget {
  .map-widget-header {
    padding: $widget-header-mobile-padding;
    flex-basis: $widget-header-mobile-height;
    .map-widget-location-search-box, .map-widget-search-box {
      padding: 6px 21px 6px 6px !important;
      margin: 1px 24px 1px 6px;
      border: 1px solid #e6e6e6;
      height: 36px;
      margin-top: -5px;
    }

    // .map-widget-search-box {
    //   // font-size: 18px;
    //   padding: 12px 15px 3px 0;
    // }
  }

  .map-widget-header .map-widget-controls .map-widget-button {
    font-size: 20px;
  }

  .map-widget-button {
    border: 1px solid #ccc !important;
    border-radius: 4px;
    padding: 3px 8px 2px 8px !important;
    flex: 0 0 38px !important;
  }

  .map-widget-title {
    font-size: 16px !important;
    line-height: 30px !important;
  }

  .address-search-component .address-search-dropdown {
    top: 50px;
    .address-search-result {
      border-bottom: 1px solid #ccc;
      padding: 8px 8px;
      font-size: 14px;
      &:last-child {
        border-bottom: none;
      }
    }

    .no-results-message, .loading-message {
      font-size: 14px;
      padding: 8px;
    }
  }

}

.map-widget {
  $column-title-margin: 6px;

  display: flex;
  flex-direction: column;
  padding: $widget-border-width 0 $widget-border-width $widget-border-width;
  color: $font-color;
  font-family: $ui-font;
  .leaflet-control-zoom{
    z-index: 1000;
  }

  .leaflet-control-recenter a,
  .leaflet-control-zoom a:last-child,
  .leaflet-control-zoom a:first-child
   {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
  }
  .leaflet-control-zoom a:first-child{
    z-index: 1001;
  }
  .leaflet-control-recenter a,
  .leaflet-control-zoom a {
      width: 30px;
      height: 30px;
      line-height: 30px;
      position: relative;
      &:hover:after{
        content: attr(aria-label);
        font-size: 13px;
        font-weight: 100;
        color: #000;
        background-color: #fff;
        border: solid 1px #989898;
        position: absolute;
        top: 25px;
        left: -10px;
        width: 70px;
        min-height: 25px;
        line-height: 25px;
      }
  }

  .leaflet-control-layers-toggle {
    width: 44px;
    height: 44px
  }

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
      font-size: 22px;
  }

  .leaflet-control-attribution,
  .leaflet-control-layers,
  .leaflet-bar {
    box-shadow: none
  }

  .leaflet-control-layers,
  .leaflet-bar {
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box
  }

  .leaflet-control-recenter a {
    font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 17px;
  }

  .crosshairs-big {
    top: 9px;
    position: absolute;
    right: 7px;
    padding: 0;
    margin: 0;
    font-size: 1em;
  }

  .map-widget-header {
    flex-basis: $widget-header-height;
    flex-grow: 0;
    flex-shrink: 0;
    padding: $widget-header-padding;
    overflow-y: hidden;
    display: flex;
    justify-content: space-between;

    background-color: $table-header-background-color;

    .map-widget-title {
      flex-grow: 1;
      flex-shrink: 0;
      line-height: $widget-header-height - (2 * $widget-header-padding);
      margin: 0 0 0 $column-title-margin;
      padding-left: 7px;
      font-weight: 600;
      font-size: $widget-title-font-size;
    }

    // the search box needs a little extra margin for focus border
    .map-widget-search-box,
    .map-widget-location-search-box {
      flex-grow: 1;
      flex-shrink: 0;
      height: $widget-header-height - (2 * $widget-header-padding) - 2px;
      margin: 1px 2px 1px $column-title-margin;
      padding: 3px 5px 3px 5px;
      border-radius: 5px;
      font-size: $widget-input-font-size;
      background-color: transparent;
      border: none;
      outline: none;
    }

    .map-widget-location-search-box {
      padding: 0 21px 0 0;
    }

    .map-widget-location-search-dropdown {
      background: red;
      z-index: 1000;
    }

    .map-widget-controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      flex-shrink: 0;
      // height: 26px;
      margin-right: $column-title-margin;
      margin-left: auto; // helpful if we need to change .column-widget-header's justify-content attribute
      cursor: default;

      .map-widget-button {
        flex: 0 0 30px;
        padding: 0 5px 0 5px;
        margin-right: 8px;
        font-size: 18px;
        // display: inline-block;
        background-color: inherit;
        border: none;
        color: $widget-button-color;
        opacity: 0.5;
        cursor: pointer;
        outline: none;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: $button-link-color;
          transition: color 0.2s;
          opacity: 1;
        }

        &.disabled {
          opacity: 0.2;
          pointer-events: none;

          &:hover {
            color: $widget-button-color;
            transition: none;
            opacity: 0.2;
          }
        }

        &:focus {
          box-shadow: 0 0 1px 1px $table-cell-selected-color;
          border-radius: 4px;
          opacity: 1;;
        }
      }
      .active-button {
        color: $font-color;
        transition: color 0.2s;
      }

      .map-widget-controls-divider {
        flex: 0 0 1px;
        height: 20px;
        margin: 0 8px 0 0;

        background-color: $widget-button-color;
        opacity: 0.5;
      }

      .reset-button {
        opacity: unset;
        color: $button-link-color;
      }
    }

    .map-widget-distance-box {
      margin: 0 0 0 $column-title-margin;
      font-size: $widget-title-font-size;
      line-height: $widget-header-height - (2 * $widget-header-padding);
      font-weight: 600;
    }
  }

  .map-widget-body {
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - #{$widget-border-width});
    height: 100%;
    overflow-x: auto;

    img.leaflet-marker-icon.floating-break-no-location {
      margin-left: 6px !important;
      margin-top: -6px !important;
    }

    img.leaflet-marker-shadow.floating-break-no-location {
      margin-left: 2px !important;
      margin-top: -10px !important;
    }
  }

  .tomtom-map {
    margin-top: $widget-border-width;
    margin-left: $widget-border-width;
    width: calc(100% - #{2 * $widget-border-width});
    height: calc(100% - #{2 * $widget-border-width});
    overflow: hidden;
    cursor: grab;
  }

  // prevent "invisible" trigger from taking up space in layout
  & > .ember-basic-dropdown-trigger {
    display: none;
  }
}

.test-map-widget {
  height: 1000px !important;
}

.leaflet-tooltip-pane {
  display: none;
}

.distance-icon {
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background: url(/map-icons/icon-distance.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.pinpoint-cursor {
  cursor: url('/map-icons/icon-pinpoint.svg') 8 21, auto !important;
}
.removeCursor{
  cursor: none;
}