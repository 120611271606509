.special-request-styles-overlay {
  // on tablet this should be higher than workspace nav bar
  z-index: 1200;
}

.special-request-styles-overlay .ember-modal-dialog {
  padding: 0;
  border-radius: 1;

  .special-request {
    width: 500px;
    height: 290px;
    color: white;
    background: #334247;
  
    .special-request-header {
      width: 100%;
      height: 35px;
      background: #171F22;
      font-size: medium;
      text-align: center;
      line-height: 2;
      padding-left: 6px;

      button {
        background-color: transparent;
        border: none;
        outline: none;
        float: right;
        padding-right: 15px;
        color: #ffffffd1;
      }
    }
  
    .special-request-body {    
      margin: 15px 28px 22px 28px;
      font-size: medium;
      font-weight: 300;

      .special-request-credential {
        margin-top: 21px;
        border: 1px solid $widget-button-color;

        .credential-header {
          margin: 0px 7px 14px 7px;
        }

        .credential-user-id, .credential-password {
          margin: 0px 10px 7px 7px;
          display: flex;
          span {
            width: 100px;
          }
        }
      }
    }

    .special-request-button {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;

      button {
        padding: 0 5px 0 5px;
        margin-right: 8px;
        font-size: 18px;
        background-color: inherit;
        border: 1px solid #8a9ca3;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        width: 100px;
        font-size: medium;
      }
    }
  }
}
