.driver-health-view {
  .container-dds {
    padding: 0.5rem;
  }

  input,
  textarea {
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
    border-right: 1px solid #aaaaaa;
    border-left: 1px solid #aaaaaa;
    border-radius: 4px;
    background-color: #ffffff;
    line-height: 1.75;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  label {
    margin: 0;
  }

  .col {
    display: flex;
    flex-direction: column;
  }
  .col-md-12 {
    margin-top: 0.5rem;
  }

  .generic-panel {
    height: 100%;

    .panel-body {
      font-size: 12px;
      font-weight: 600;

      .container {
        padding: 0 4rem 0 4rem;

        .row {
          margin-bottom: 0.5rem;
        }

        .form-components-text {
          width: 80%;
        }

        .form-components-text-area {
          width: 80%;
        }

        .form-component-input-mask-layout {
          width: 80%;
        }

        .ember-power-select-trigger {
          height: 25px;
          width: 80%;
          &.error {
            border: 2px solid $otp-danger !important;
          }
        }

        .datetimeflatpickr-wrapper {
          width: 80%;
          input {
            width: 100%;
            border: 0;
          }
          &.error {
            border: 2px solid $otp-danger !important;
          }
        }

        .ember-text-field-holder {
          width: 80%;
        }
      }
    }
  }
}
