.road-call-view {

  input,
  textarea {
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
    border-right: 1px solid #aaaaaa;
    border-left: 1px solid #aaaaaa;
    border-radius: 4px;
    background-color: #ffffff;
    line-height: 1.75;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .error{
    input,
    .ember-power-select-trigger {
      border: solid 2px $error-dds !important;
    }
  }

  button {
    @include button();
    height: 20px;
    line-height: 1rem;
    &:hover {
      i {
        color: $link-primary;
      }
    }
    &:disabled {
      i {
        color: inherit;
      }
    }
  }

  .form-panel-wrapper {
    display: flex;
    margin-top: 0.5rem;

    .road-call {
      flex: 0 0 40%;
    }

    .road-call-notes {
      flex: 1 1 auto;
      margin-left: 0.5rem;
      .container {
        padding: 0.5rem 2rem 0 1rem
      }
    }
    .enter-multiple-vehicle {
      flex: 0 0 100%;
      .container{
        max-width: calc(45% - 8px);
        margin-left: 0;
        .road-call-vehicle {
          margin-left: 0;
          .input-group {
            label {
              flex: 0 0 20%;
            }
            .ember-text-field-holder{
              flex: 0 0 66%;
              height: 25px;
            }
            .new_road_call-button {
              height: 1.6rem;
            }
            .button-holder {
              flex: 0 0 23%;
              margin-left: 2%;
              height: 25px;
              cursor: pointer;

              .new_road_call-button{
                flex: 0 0 23%;

                &:disabled {
                  color: #AEAEAE;
                  border-color: #B4B4B4;
                  #grad {
                    background-image: linear-gradient( #fff, #E0E0E0);
                  }
                  &:hover {
                    cursor: not-allowed;
                  }
                }

              }
            }
          }
        }
      }
    }

  }

  .generic-panel {
    .panel-body {
      font-size: 12px;
      font-weight: 600;

      .container {
        padding: 0 2rem 0 2rem;
        .input-group {
          align-items: center;
          flex-wrap: nowrap;
          margin-bottom: 0.5rem;

          label {
            flex: 0 0 35%;
            white-space: nowrap;
            margin: 0;
          }

          .road-call-popup {
            flex: 0 0 58%;
            margin-right: 0.5rem;
          }

          .search {
            background-color: #fff;

            &:disabled {
              color: #AEAEAE;
              border-color: #B4B4B4;
              #grad {
                background-image: linear-gradient( #fff, #E0E0E0);
              }
              &:hover {
                cursor: not-allowed;
              }
            }
          }

          .ember-power-select-trigger {
            flex: 1 1 30%;
            height: 25px;
          }

          .ember-power-select-trigger[aria-disabled=true] {
            color: #AEAEAE;
            border-color: #B4B4B4;
            #grad {
                background-image: linear-gradient( #fff, #E0E0E0);
            }
            &:hover {
              cursor: not-allowed;
            }
          }


          .ember-text-field {
            flex: 1 1 30%;
            height: 25px;

            &:disabled {
              color: #AEAEAE;
              border-color: #B4B4B4;
              #grad {
                background-image: linear-gradient( #fff, #E0E0E0);
              }
              &:hover {
                cursor: not-allowed;
              }
            }
          }

          .datetimeflatpickr-wrapper {
            flex: 1 1 30%;
            input {
              height: 25px;
              width: 100%;

              &:disabled {
                color: #AEAEAE;
                border-color: #B4B4B4;
                #grad {
                  background-image: linear-gradient( #fff, #E0E0E0);
                }
                &:hover {
                  cursor: not-allowed;
                }
              }
            }
          }

          .ember-text-field-holder {
            flex: 1 1 30%;
            .ember-text-field {
              height: 25px;
              width: 100%;
            }
          }

          .ember-checkbox {
            flex: 0 0 25%;
            input {
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
      }
    }
  }
}

.search-modal.route-id, .search-modal.driver-id {
  width: 650px;
}
