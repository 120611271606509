.help-div {
  display: none;
}

.help-link-component {
  display: contents;

  .help-link-button {
    padding: 0 5px 0 5px;
    margin-right: 8px;
    display: inline-block;
    margin-top: -2px;
    font-size: 18px;
    background-color: inherit;
    border: none;
    color: $icon-light-color;
    opacity: 0.5;
    cursor: pointer;
    outline: none;

    &:last-child {
      margin-right: 0;
    }
    // height: 25px;

    &:focus {
      box-shadow: 0 0 1px 1px $table-cell-selected-color;
      border-radius: 4px;
      opacity: 1;
    }

    &:hover {
      color: dds("700");
      transition: color 0.2s;
      opacity: 1;
    }
  }
}

.column-widget-controls .help-link-button {
  padding: 0 10px 0 5px;
}

.map-widget-controls .help-link-button {
  padding: 2px 10px 0 5px;
}

.form-widget-controls .help-link-button {
  padding: 0 1px 0 5px;
}
