.schedule-metrics {
  .secondary-window {
    .secondary-window-modal {
      .secondary-window-body {
        background-color: transparent;
        .schedule-metrics-wrapper {
          width: 100%;
          .metrics-columns-container {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 20px;
            margin-top: 40px;
          }

          .labels-box {
            display: flex;
            flex-direction: column;
            background-color: white;
            width: 350px;
          }

          .values-box {
            display: flex;
            flex-direction: column;
            background-color: white;
            width: 350px;
          }

          .seperator-box {
            display: flex;
            flex-direction: column;
          }

          .metrics-item {
            display: flex;
            align-items: center;
            height: 50px;
          }

          .item-label {
            font-weight: 600;
            color: #303e44;
            font-family:$ui-font;
            font-size: 18px;
            flex: 1;
            padding: 0 20px;
            display: flex;
            text-align: right;
            justify-content: flex-end;
          }

          .separator {
            font-size: 24px;
            color: #303e44;
            align-self: center;
          }

          .item-value {
            font-weight: 400;
            color: #555;
            font-family:$ui-font;
            font-size: 18px;
            flex: 1;
            padding: 0 20px;
            display: flex;
          }
        }
      }
    }
  }
}
