.ada-eligibility-container {
  background-image: linear-gradient(#334247, #171F22);
  color: white;
  margin: 20px;
  padding: 0px;
 
  #boundary-map {
    width: 922px;
    height: 477px;
    border: solid 2px grey;
    margin: 5px 0 11px 19px;
    border-radius: 5px;
  }
  
  .booking-map-header {
    padding: 5px 20px;
  }
  #booking-map {
    width: 100%;
    height: 76vh;
  }

  .centered-scrolling-overlay {
    position: relative;
    min-height: 100vh;
    padding: 1em;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  /* basic modal style (an example, this is not necessary for the centering) */
  .centered-scrolling-container {
    position: relative;
    background-color: white;
    min-width: 60em;
    max-width: 650px;
    min-height: 30em;
    padding: 0;
    margin-top: 80px;
    margin-bottom: 30px;
    box-sizing: border-box;
    margin-left: 200px;
    box-shadow: 0px 4px 25px 4px rgba(0, 0, 0, 0.30);
  }
  .sp-header {
    -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .sp-heading {
    padding: 3px 9px;
    font-weight: 500;
    font-size: 18px;
    background-color: #F6F6F6;
    border-radius: 7px 7px 0 0;
    .x-button {
      float: right;
      color: rgb(59, 57, 57);
    }
    .question-image {
      padding: 2px;
      float: right;
      color: #bdc5d4a8;
    }
  }

  .addressvalidationButton {
    margin-left: 300px !important;
    width: 100px !important;

  }
}

.ada-red-marker-labels {
  border-radius: 4px;
  color: red;
}

.ada-black-marker-labels {
  border-radius: 4px;
  color: black;
}

.ember-modal-overlay.translucent.ada-eligibility-overlay {
  z-index: zindex(action-modal);
}

.ada-eligibility-container {
  overflow-y: auto;
  height: 700px;
  width: 50%;
}

.ada-response {
  margin: 10px;
  float: right;
  display: flex;
  justify-content: flex-end;
  .btn-secondary {
    color: #bab6b6;
    background-color: #dfdfdf;
    border-color: #bab6b6;
    min-width: 90px;
    line-height: 15px;
    font-weight: 500;
    border-radius: 4px;
    margin-right: 20px;
    margin-bottom: 15px;
    background-image: linear-gradient(#334247, #171f22);
  }
  .btn-secondary:hover {
    color: white;
    border: 1px solid white;
  }
}

.ada-eligibility-wrapper {
  .sp-header {
    -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .sp-heading {
    background-image: linear-gradient(#334247, #171F22);
    color: white;
    padding: 3px 9px;
    font-weight: 500;
    font-size: 17px;
    background-color: #F6F6F6;
    border-radius: 7px 7px 0 0;
    text-align: center;
    .x-button {
      float: right;
      color: white;

      button.close {
        color: white;
        line-height: 0.8;
      }
    }
    .question-image {
      padding: 2px;
      float: right;
      color: #bdc5d4a8;
    }
  } 
  .note-label {
    margin-left: 20px;
  }
  .ada-type-table {
    margin: 15px 20px 0px 20px;

    body {
      position: absolute;
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      font-family: 'Helvetica';
    }
    .main-container {
      width: 100%;
      height: 100%;
      display: flex;
      margin: 0;
      justify-content: center;
      align-items: center;
      background-color: #fafafa;
      border-radius: 4px;
    }
    
    .table-container {
      display: flex;
      flex-flow: column nowrap;
      background-color: white;
      width: 100%;
      margin: 0 auto;
      border-radius: 4px;
      border: 1px solid #DADADA;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, .08);
    }
    
    .table-row {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      border-bottom: 1px solid #dadada;
    }
    
    .heading {
      background-color: #ececec;
      color: #3e3e3e;
    }
    
    .row-item {
      display: flex;
      // flex: 1;
      font-size: 11px;
      padding: 3px 0 0 18px;
      justify-content: left;
      align-items: center;
      color: black;
    }

    .title-table.row-item {
      font-size: 13px;
      font-weight: 500;
    }

    .header-table.row-item {
      font-weight: 500;
      background-color: #f5f5f7;
    }

    .row-item.col-type {
      width: 124px;
    }

    .row-item.col-desc {
      width: 536px;
    }

    .row-item.col-date {
      width: 129px;
    }
    
    .row-sub-container {
      display: flex;
      flex-flow: column nowrap;
      flex: 1;
    }
    
    .row-sub-container .row-item {
      padding: 8px 0;
      border-bottom: 1px solid #dadada;
    }
    
    .table-row:last-child,
    .row-sub-container .row-item:last-child {
      border-bottom: 0;
    }
  }
}

.global-spinner{
  z-index: zindex(dropdown);
}
