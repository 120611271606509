.ada-boundary-container {
    margin: 10px 0px;
    margin-left: auto;
    margin-right: auto;
}

.ada-boundaries-table {
    .table-component-body{
        border: 0px !important;
        overflow-x: hidden;
        flex-basis: 150px;
        .column-controls {
            visibility: hidden;
        }
        .lt-column-resizer {
            display: none;
        }
        .lt-head-wrap {
            padding-right: 0 !important;
        }
        .ember-light-table {
            .lt-body-wrap {
                margin-top: 0px;
            }
            table {
            border: 0px !important;
            border-collapse: collapse;
            }
        }
    }
}

.fixed-route {
    width: 100%;
    display: flex;
}

.ada-fixed-route-input {
    padding-inline-start: 2%;
}

.ada-boundary-information {
    padding-bottom: 10px;
    font-size: 14px;
}

.ada-boundary-fixed-route {
    padding-bottom: 1%;
}

.lt-head-wrap {
    .table-component-body {
        flex-basis: 100px;
        .ember-light-table {
            .lt-body-wrap {
                margin-top: 0px;
            }
            table {
            border: 0px !important;
            border-collapse: collapse;
            }
        }
    }
    padding-right: 0 !important;
}

.fixed-route-name-size {
    font-size: 12px;
}

.ada-map-header {
    margin-left: 8px;
    font-weight: bold;
}

.ada-boundary-map {
    .map-widget-body {
        height: 300px !important;
    }
}

.map-widget {
    height: 100%;
}

.ada-boundary-information {
    margin-bottom: 15px;
}