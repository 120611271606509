.vehicle-driver-manager-container {

  padding: 0 2.5rem; // 40px

  .widget {
    display: flex;
    height: 760px; // keep it flat a height for now
    flex-direction: column;
    box-shadow: 0px 0px 4px 0px $widget-shadow-color;
    border-radius: 8px;

    .row {
      margin: 0;
    }
    .widget-header {
      background: #ffffff;
      box-shadow: 0 2px 4px rgba(199, 199, 199, 0.5);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: 1px solid #d4d6d7;
      padding: 5px;

      .tab-group {
        align-content: center;
        align-items: center;
        height: inherit;

        .ember-basic-dropdown {
          margin-left: 2rem;
        }
        .ember-power-select-group .ember-power-select-group-name {
          color: $dds-grey-100;
          font-size: 12px;
          font-weight: normal;
        }
        .ember-power-select-selected-item{
          font-size: 15px;
        }

        .ember-power-select-trigger {
          overflow-x: unset;
          min-height: unset;
          width: 200px;
          line-height: 1.25;
        }
      }

      .context {
        display: flex;
        align-items: center;
        button {
          flex: 0 0 100px;
          @include button();
          height: 26px;
          width: 100px;
          border: 1px solid #B4B4B4;
          border-radius: 2px;
          background: $button-bg-grey;
          line-height: 1.1;
          cursor: pointer;

          span {
            flex: auto;
            font-size: 14px;
            font-weight: 500;
          }
        }

        h5 {
          font-size: $font-size-base-dds;
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
          white-space: nowrap;
          margin: 0 1rem 0 1rem;
        }

        .ember-basic-dropdown-trigger {
          flex: 0 0 200px;
          height: 28px;
          min-height: 0;
          overflow: hidden;
        }
      }

      .actions {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: end;
        button {
          @include button();
          height: 26px;
          width: 90px;
          border: 1px solid #B4B4B4;
          border-radius: 2px;
          background: $button-bg-grey;
          margin-left: 20px;
          line-height: 1.1;
          cursor: pointer;

          span {
            flex: auto;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }

    .widget-body {
      background: $light-dds;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      .tab-wrapper {
        display: flex;
        padding: 1rem;
        flex-direction: column;
        min-height: 650px;
        overflow: auto;
        .driver-table,
        .vehicle-table {
          display: flex;
          flex-direction: column;
          min-height: 650px;
        }
      }
    }
  }
  &.driver-vehicle-drop-down {
    .widget {

      .widget-header {

        .ember-power-select-selected-item {
          position: absolute;
          top: 0;
        }
      }

    }
  }

}

