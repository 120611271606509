.dashboard-widget-new {
  .playback-widget-body {
    overflow-x: visible;
  }

  .playback-widget-form {
    .form-container {
      select {
        word-wrap: normal;
        width: 80%;
        height: 22px;
        color: #999999;
        display: block;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .table-cell {
    border: 1px solid black;
    line-height: 30px;
    text-align: left;
    padding: 0 4px;
  }
  .playback-slider-table {
    width: 100%;
    display: flex;
    height: 55px;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    margin-left: 0px;
  }

  .loader{
    margin-left: 48%;
    margin-top: 5%;
    margin-bottom: 15%;
  }

  .round {
    position: relative;
  }

  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }

  .playback-table-header-column{
    height: 23px;
    overflow: hidden;
    padding-right: 17px;
    width: 1366px;
    // width: 100%;
    font-weight: 700;
    display: flex;
    text-transform: capitalize;
    background-color: rgba(230, 231, 235, 0.4);
    flex-direction: row;
    align-items: center;
  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .filter_dropdown{
    font-size: 12px;
    display: block;
    width: 100%;
    padding: 0px 7px 0 7px;
    line-height: 1.5;
    color: #303e44;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #cdd4d6;
    border-radius: 3px;
    outline: none;
  }

  .option-value{
    color: #212a2e;
  }

  .confirm-dialog_rvd{
    position: absolute;
    width: 360px;
    min-height: 97px;
    background-color: #263238;
    margin: 0 auto;
    padding-top: 19px;
    top: 100px;
    left: 0;
    right: 0;
    z-index: 1000;
    border-radius: 4px;
    // box-shadow: 0 2px 24px 0 rgb(0 0 0 / 50%);
    overflow: hidden;
  }

  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
  }

  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  .table-wrapper {
    display: flex;
    height: 100%;
    position: relative;
    .grid-table-left {
      position: relative;
      width: 33%;
      grid-column: col;
      margin: 0 5px 5px 5px;
      border: 1px solid #ccced0;
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .grid-table-right {
      position: relative;
      height: 100%;
      grid-column: col 2 / span 2;
      margin: 0 5px 5px 5px;
      border: 1px solid #ccced0;
      border-radius: 4px;
      width: 71%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      td,th{
        white-space: nowrap;
      }
    }
    .playback-widget-table-body{
      height: calc(100% - 40px) !important;
    }
    .ReactVirtualized__Table__row{
      background-color: #fff;
      &:nth-child(2n){
        background-color: rgba(230, 231, 235, 0.4);
      }
    }
  }

  .playback-widget-panel caption {
    margin-left: 512px;
  }
  .playback-widget-panel .panel-row {
    margin-left: 250px !important;
    margin-top: 0px !important;
  }
  .playback-widget-panel #sliderInput {
    width: 368px;
    height: 3px;
    border-radius: 4px;
    border: transparent;
    background: #d3d3d3;
  }

  #sliderInput::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid;
    border-color: white;
    background: #5a8e32;
    cursor: pointer;
  }

  #sliderInput::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border: 2px solid;
    border-color: white;
    background: #5a8e32;
    cursor: pointer;
  }
}
.playback-widget-table {
  display: block !important;
  flex-basis: auto !important;
}

.playback-widget-table .ReactVirtualized__Table__headerColumn {
  display: flex;
  flex-direction: row;
  flex: inherit;
  padding: 0;
  border-right: 0px !important;
}
.playback-widget-table .column-label {
  flex: auto;
}
.playback-widget-table .column-label {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 6px;
}
.playback-widget-table .DragHandle {
  flex: 0 0 16px;
  z-index: 2;
  cursor: col-resize;
  color: #0085ff;
  right: auto !important;
  left: auto !important;
}
.playback-widget-table .PlaybackDragHandleIcon {
  flex: 0 0 12px;
  display: flex !important;
  flex-direction: column;
  position: inherit !important;
  margin: 0px !important;
  min-width: 5px;
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent calc(48% - 1px),
    #ccc calc(48% - 1px),
    #ccc calc(50% + 0px),
    transparent calc(50% + 0px),
    transparent 100%
  ) no-repeat center/2px 100%;
}

.playback-widget-button-new {
  flex: 0 0 30px;
  margin-right: 8px;
  font-size: 18px;
  display: inline-block;
  background-color: inherit;
  border: none;
  color: $widget-button-color;
  opacity: 0.5;
  cursor: pointer;
  outline: none;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    transition: color 0.2s;
    opacity: 1;
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;

    &:hover {
      color: $widget-button-color;
      transition: none;
      opacity: 0.2;
    }
  }

  &:focus {
    box-shadow: 0 0 1px 1px $table-cell-selected-color;
    border-radius: 4px;
    opacity: 1;
  }
}

.vertical-align-top{
  vertical-align :top;
}
.radius{
  display: flex;
    align-items: center;
    input[type="text"]{
      width: 100px;
      margin: 0 6px;
    }

}

/* Collection default theme */

// .ReactVirtualized__Collection {
// }

// .ReactVirtualized__Collection__innerScrollContainer {
// }

// /* Grid default theme */

// .ReactVirtualized__Grid {
// }

// .ReactVirtualized__Grid__innerScrollContainer {
// }

/* Table default theme */

.ReactVirtualized__Table {
  display: flex;
  flex-direction: column;
}

// .ReactVirtualized__Table__Grid {
// }

.ReactVirtualized__Table__row {
  width: 100%;
}

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  display: flex;
  text-transform: capitalize;
  background-color: rgba(230, 231, 235, 0.4);
  flex-direction: row;
  align-items: center;
}
.ReactVirtualized__Table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}
.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__rowColumn {
  min-width: 0px;
  padding-left: 6px;
}

.ReactVirtualized__Table__headerTruncatedText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: inherit;
  display: inline-block;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: flex;
  align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}

/* List default theme */


.mt-10{
  margin-top: 10px !important;
}



.virtualized_select{
  *[class$='-control'] {
    min-height: 0 !important;
    border-color: rgba(38, 50, 56, 0.2)!important;
    border-radius: 0px !important;
    max-width: 150px !important;
  }
  *[class$='-ValueContainer'] {
    padding: 0px 8px!important;
  }
  *[class$='-Input'] {
    margin: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  *[class$='-indicatorContainer'] {
    padding: 0 3px!important;
  }
  *[class$='-indicatorSeparator'] {
    display: none;
  }
  *[class$='-placeholder'] {
    font-weight: normal;
    margin: 0px !important;
  }
  *[data-value] {
    margin: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.virtualized_select_rvd{
  *[class$='-control'] {
    min-height: 0 !important;
    border-color: rgba(38, 50, 56, 0.2)!important;
    border-radius: 0px !important;
    max-width: 250px !important;
  }
  *[class$='-ValueContainer'] {
    padding: 0px 8px!important;
  }
  *[class$='-Input'] {
    margin: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  *[class$='-indicatorContainer'] {
    padding: 0 3px!important;
  }
  *[class$='-indicatorSeparator'] {
    display: none;
  }
  *[class$='-placeholder'] {
    font-weight: normal;
    margin: 0px !important;
  }
  *[data-value] {
    margin: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}


.virtualized_select_address{
  *[class$='-control'] {
    width: 100px;
    min-height: 0 !important;
    border-color: rgba(38, 50, 56, 0.2)!important;
    border-radius: 0px !important;
  }
  *[class$='-ValueContainer'] {
    padding: 0px 8px!important;
  }
  *[class$='-Input'] {
    margin: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  *[class$='-indicatorContainer'] {
    padding: 0 3px!important;
  }
  *[class$='-placeholder'] {
    font-weight: normal;
    margin: 0px !important;
  }
}



.tile-dashboard-content.tile-size-100 .grid-stack .grid-stack-item[data-gs-height="8"] {
  height: 900px;
}


/*

Input styles
------------------
*/
.search-bar {
  width: 117px;
  padding: 12px 8px;
  font-size: 12px;
  height: 8px;
  color: #343434;
  border: 1px solid rgba(38, 50, 56, 0.2);
  font-weight: normal;
  border-radius: 0;
}

.search-bar:focus {
  outline: none;
}

/*
SearchBar container
*/

.auto {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  z-index: 1;
  position: relative
}

/*Cancel button styles*/

.cancel-btn {
  color: rgb(99, 99, 99);
  border: none;
  outline: none;
  font-weight: 500;
  z-index: 1;
  position: absolute;
    right: 0;
    height: 100%;
    width: 22px;
}

.search {
  display: flex;
  flex-direction: row;
}


/*
search results container div
*/

.search-results {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: none;
  width: 263px;
  max-height: 400px;
  padding: 10px;
  overflow-y: scroll;
  margin-top: 5px;
  border-radius: 0.2rem;
  position: absolute;
    top: 25px;
}

/*
SearchPreview Component
*/

.search-preview {
  transition: 1s;
  display: flex;
  justify-content: space-between;
  padding: 5px 3px;
  padding-right: 10px;
  border-top: 1px solid;
  border-color: rgb(241, 241, 241);
  align-items: baseline;
}

.first {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.second {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.sub-header {
  font-weight: 400;
  font-size: 8px;
  color: rgb(121, 121, 121);
}
.name {
  font-size: 12px;
  color: rgb(74, 74, 74);
  font-weight: 500;
  margin: 0rem 0;
}
.age {
  font-size: 15px;
  margin: 3px 0px;
  color: rgb(74, 74, 74);
  font-weight: 600;
}

.start {
  border-top: none;
}

.date-input {
  display: inline-block;
  position: relative;
}

.date-input:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 2px;
  // background-image: url('/icons/down-arrow.svg');
}

.btnbtn-outline-secondaryplaces-inline-button{
  background-color: inherit;
  padding: 0px;
  font-size: 14px;
  color: #1c2023;
  cursor: pointer;
  float: left;
  margin: 3px;
  letter-spacing: 0.5px;
  font-weight: 500;
  width: 33px;
  border-radius: 0.1rem;
  height: 26px;
  line-height: 1;
}

//Tooltip

.tooltips {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltips .tooltipstext {
  width: 120px;
  top: 100%;
  left: 50%;
  margin-left: -60px;

  position: absolute;
  z-index: 1;
}

.tooltips:hover .tooltipstext {
  visibility: visible;
}


.table-component-content div {
  padding: 5px 10px;
}

.playback-widget .playback-widget-table .playback-widget-table-header {
  padding: 0px 6px !important ;
  overflow-y: visible !important ;
  align-items: center;
}


.download-dropdown{
  position: absolute;
  top: 100%;
    right: 38px;

  button {
    border: none;
    padding: 4px 9px;
  &:hover {
    cursor: pointer;
    background-color: #689f38;
    color: white;
  }
}

}



input[type=range] {
  -webkit-appearance: none;
  margin: 20px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background:  #5a8e32;
  border-radius: 25px;
}
input[type=range]::-webkit-slider-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0,0,0, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background:  #5a8e32;
}
.range-wrap{
  width: 450px;
  position: relative;
}
.range-value{
  margin-top: 20px;
    position: absolute;
    top: -50%;
    z-index: 999;
    line-height: 18px;
    text-align: center;
    background: #5a8e32;
    color: white;
    font-size: 10px;
    display: block;
    padding: 0 6px;
    left: 7px;
    transform: translate(-50%, 0px);
    border-radius: 3px;
    min-width: 60px;
    white-space: nowrap;
}
.range-value:before{
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 6px solid  #5a8e32;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}

.playback-widget .playback-widget-panel .circle {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
}
.playback-widget .playback-widget-panel .panel-row .playback-widget-slider label {
  line-height: 1;
}

.loadingMessage{
  position: absolute;
    top: 50%;
    z-index: 9;
    left: 0;
    right: 0;
    text-align: center;
}


.map-widget .leaflet-control-zoom a:hover:after {
  content:'';
  font-size: 13px;
  font-weight: 100;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  min-height: 0px;
  line-height: 0px;
}
