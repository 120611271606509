.fleet-manager-container {

  padding: 0 2.5rem; // 40px

  .widget {
    display: flex;
    height: 500px; // keep it flat a height for now
    flex-direction: column;
    box-shadow: 0px 0px 4px 0px $widget-shadow-color;
    border-radius: 8px;
    padding: 15px;

    .row {
      margin: 0;
    }
    .widget-header {
      background: #ffffff;
      box-shadow: 0 2px 4px rgba(199, 199, 199, 0.5);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: 1px solid #d4d6d7;
      padding: 5px;

      .context {
        display: flex;
        align-items: center;
        button {
          flex: 0 0 100px;
          @include button();
          height: 26px;
          width: 100px;
          border: 1px solid #B4B4B4;
          border-radius: 2px;
          background: $button-bg-grey;
          line-height: 1.1;
          cursor: pointer;

          span {
            flex: auto;
            font-size: 14px;
            font-weight: 500;
          }
        }

        h5 {
          font-size: $font-size-base-dds;
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
          white-space: nowrap;
          margin: 0 1rem 0 1rem;
        }
      }

      .actions {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: end;
        button {
          @include button();
          height: 26px;
          width: 90px;
          border: 1px solid #B4B4B4;
          border-radius: 2px;
          background: $button-bg-grey;
          margin-left: 20px;
          line-height: 1.1;
          cursor: pointer;

          span {
            flex: auto;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }

    .widget-body {
      background: transparent;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      .vehicle-details-container {
        margin-top: 18px;
        background: #F5F5F7;
        border-radius: 5px;
        box-shadow: 0px 2px 5px rgba(38, 50, 56, 0.50);
      }
    }
  }

}

