.dashboard-save-as {
  .dashboard-save-as-body {
    padding: 10px 30px 10px 39px;

    input {
      width: 100%;
    }

    .dashboard-save-as-body-label {
      margin: 15px 0 12px 0;
    }

    .dashboard-save-as-body-input {
      display: inline-flex;
      align-items: center;
      width: 100%;
  
      input.workspace-name-input {
        width: 85%;
      }
  
      input.workspace-name-checkbox {
        width: 5%;
      }
      label.workspace-name-checkbox-label {
        width: 10%;
        margin: 0;
        margin-left: -15px;
      }
    }
  }

  .dashboard-save-as-controls {
    padding: 10px 30px 0 39px;
  }
}
