.global-spinner {
  background: rgba(255, 255, 255, .8);

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: zindex(modal);

  .spinner-wrapper {
    position: relative;
  }

  .spinner-gif {
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  .spinner-text {
    position: absolute;
    top: 72%;
    left: 38%;
    color: white;
    font-family: $ui-font;
    font-size: 24px;
  }
}


