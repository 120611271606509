// Variables
$button-height: 70px;
$button-width: 100%;

.optimize-schedule {
  .secondary-window {
    .secondary-window-modal {
      .secondary-window-header {
        text-align: center;
      }
      .secondary-window-body {
        background-color: transparent;
        .optimize-schedule-wrapper {
          width: 100%;
          .optimize-schedule-columns-container {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 20px;
          }

          .seperator-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 100px;
            height: 500px;

            .line {
              width: 0px;
              height: 450px;
              box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.20);
              border-left: 10px solid #303E44;
              border-radius: 8px;
            }

            .img {
              width: 82px;
              height: 82px;
            }
          }

          .optimize-strategy-container {
            background: transparent;
            box-sizing: border-box;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            padding: 20px;
            width: 650px;

            .optimize-strategy-heading-section {
              padding-bottom: 10px;
              color: #303E44;
              font-size: 26px;
              font-family: $ui-font, sans-serif;
              font-weight: bold;
              text-align: center;
            }

            .optimize-strategy-body-section {
              background-color: white;
              padding: 30px 30px 10px 30px;
              border-radius: 15px;

              .radio-button-input {
                display: flex;
                align-items: center;
                gap: 20px;
              }


              .radio-button-input input[type="radio"] {
                width: 30px;
                height: 30px;
                cursor: pointer;
              }

              .radio-button-input input[type="radio"]:checked {
                accent-color: #679F38;
              }

              .radio-button-label {
                font-size: 22px;
                font-family: $ui-font, sans-serif;
                font-weight: 600;
                color: #303E44;
                align-self: center;
                margin: 0;
                padding: 0;
                line-height: 1; /* Adjust line height to match the radio button */
              }
            }

            .upload-section {
              background-color: transparent;
              margin-left: auto;
              margin-right: auto;
            }

            .optimize-btn-section {
              background-color: transparent;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .optimize-btn {
            width: 120px;
            height: 55px;
            box-shadow: 0px 2px 5px rgba(38, 50, 56, 0.50);
            padding: 10px;
            margin: 10px;
            border-radius: 8px;
            text-align: center;
            font-size: 22px;
            font-family: $ui-font, sans-serif;
            font-weight: bold;
            display: inline-block;
          }

          .optimize-btn-no-selection {
            background-color: white;
            color: black;
            cursor: not-allowed;
            pointer-events: none;
          }

          .optimize-btn-selected {
            background: $dds-primary-gradient;
            color: white;
            cursor: pointer;
            pointer-events: auto;
          }

          .icon-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            color: black;
            border: none;
            padding: 0;
            border-radius: 20px;
            width: $button-width;
            height: $button-height;
            justify-content: center;

            &:disabled {
              opacity: 1;
            }
          }

          .check-icon {
            color: white;
            height: $button-height;
            background: $dds-primary-gradient;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            width: 100%;
          }

          .btn-text {
            color: #303E44;
            width: $button-width;
            font-size: $font-size-sm-dds;
            font-family: $ui-font, sans-serif;
            font-weight: $font-weight-base-dds;
            line-height: 26px;
            text-align: center;
            word-wrap: break-word;
            border: none;
            background: transparent;
            padding: 0px 0px 15px 0px;
          }

          .horizontal-line-with-or {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;

            div.horizontal-line{
              width: 85px;
              background-color: black;
              height: 9px;
              float: left;
              border-radius: 4.5px;
            }

            .btn-or-text {
              background-color: black;
              color: whitesmoke;
              border: 3px solid black;
              border-radius: 50%;
              font-size: 29px;
              margin: 0px 15px 0px 15px;

              .btn-or-text-inside {
                padding-left: 5px;
                padding-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}
