
.route-form-widget{
    .generic-panel {
        .panel-header {
            justify-content: unset;
    
            .panel-title{
                padding-left: 10px;
            }
        }
      }
}