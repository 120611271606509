.menu-navbar-wrapper {

  .menu-navbar-container {
   //margin: 0px 5px 10px 10px;
   height: $nav-height;
  }

  .schedule-dashboard-navbar {
    background-color: #263238;
  }

  .menu-navbar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 6px 10px;
    background: transparent;
    gap: 10px; /* Adds spacing between buttons */
  }

  .space-evenly {
    justify-content: space-evenly;
  }

  .nav-button {
    width: 230px;
    height: 37px;
    border-radius: 8px;
    padding: 5px 15px;
    border: none;
    display: flex;
    justify-content: center; /* Align items to the start */
    align-items: center; /* Vertically centers items */
    gap: 10px; // Control gap between the text and image
    outline: none;

    img {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }

    .nav-button-text {
      color: #ffffff;
      font-size: 18px;
      font-family: 'Sarabun', sans-serif;
      white-space: nowrap; /* Prevent text wrapping */
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background: $dds-primary-gradient;
      cursor: pointer;
    }

    &.highlighted {
      background: $dds-primary-gradient;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .scale-0-75 {
    transform: scale(0.75);
  }

  .scale-1-5 {
    transform: scale(1.5);
  }
  .scale-2 {
    transform: scale(2);
  }

  .scale-1-75 {
    transform: scale(1.75);
  }

  /* Media query for small screens */
  @media (max-width: 1023px) {
    .menu-navbar {
      flex-wrap: wrap;
    }

    .menu-navbar-container {
      height: auto;
    }
  }
}
