.form-components-text-area {
  flex: 1 1 auto;
  &:disabled {
    background-color: transparent;
  }
  text-area {
    @include edit-input();
    text-overflow: ellipsis;
    &:disabled {
      background-color: transparent;
      cursor: not-allowed;
    }
    &:read-only {
      background-color: transparent;
      cursor: not-allowed;
    }
  }

  &.error {
    text-area {
      border: 2px solid $otp-danger !important;
    }
  }
}
