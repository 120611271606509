.table-base-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 2px;
  
   
  .ember-basic-dropdown-trigger {
    overflow: inherit;
    text-overflow: inherit;
    white-space: inherit;
  }

  &:focus {
    // see `.lt-cell:focus-within` in `column-widget.scss` for focus styling
    outline: none;
  }
}
.table-base-cell-white-space{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  padding-right: 2px;
  height:17.3px;

  .ember-basic-dropdown-trigger {
    overflow: inherit;
    text-overflow: inherit;
    white-space: inherit;
  }

  &:focus {
    // see `.lt-cell:focus-within` in `column-widget.scss` for focus styling
    outline: none;
  }
}
