.card-item {
  .card {
    border: none;
    width: 20rem;
    height: 25rem;
    background-color: transparent;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    margin: 0 80px;
  }

  .image-container {
    width: 100%;
    height: 100%;
    position: relative;
    // background: $dds-primary-gradient;
    background-color: #263238;
    border-radius: 40px;
    border-color: transparent;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(38, 50, 56, 0.5);

    &:hover {
      background: $dds-primary-gradient;
    }
  }

  .card-title {
    color: #303E44;
    border: none;
    font-size: 2rem;
    font-family: 'Sarabun', sans-serif;
    font-weight: 600;
    line-height: 26px;
    word-wrap: break-word;
    text-align: center;
  }
}
