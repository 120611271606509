.release-routes-table{
    .table-check-box-column,.ember-light-table input[type="checkbox"] {
        display: none;
    }
    .table-component-body {
        .ember-light-table{ 
            .lt-body {
                tr .providerDetails {
                    color: blue;
                }
            }
        }
    }
}

.release-routes-provider-table{
    .table-component-body {
        .ember-light-table{
            table {
                border: 0px solid #cdd4d6 !important;
            }
        }
    }
}
