.ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center {
  .driver-break-master-styles-overlay {
        padding: 0;
        border-radius: 1;
        z-index: zindex(action-modal);
        outline: none;
        &.ember-modal-overlay.translucent {
          background-color: rgba(38,50,56,0.2);
        }
      
      .ember-modal-dialog {
          background-color: transparent;
          box-shadow: none;
          z-index: zindex(action-modal);

        .driver-break-master {
          background: $light-dds;
          border-radius: 10px;
          box-shadow: 1px 2px 6px 1px rgba(0,0,0,0.4);
      
          .driver-break-master-header {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: $background-lighter;
            font-size: $font-size-base-dds;
            border-radius: 10px 10px 0 0;
            padding: 0.5rem;
            @include box-shadow(0, 2px, 4px, rgba(148,148,148,0.5));

            .table-component-content {
              z-index: calc(zindex(action-modal) + 1);
            }

            h5 {
              font-size: $font-size-base-dds;
              margin: 0;
              font-weight: $font-weight-bold-dds;
            }

            .driver-break-master-header-buttons {
              display: flex;
              align-items: center;
              padding: 0 0.5rem 0 0.5rem;

              i {
                @include icon($icon-light-color);
                font-size: 1em;
                margin-left: 0.5rem;
                opacity: 1;
              }
            }
      

          }
      
          .driver-break-master-body {
            padding: 1rem;
            font-size: small;
            font-weight: 100;

            .search-input {
              height: 30px;
              display: flex;
              justify-content: space-between;
              align-content: baseline;
              margin-bottom: 0.5rem;
              border: 1px solid #979797;
              border-radius: 2px;
              box-shadow: 0 2px 4px 0 rgba(135,135,135,0.5);
              // the search box needs a little extra margin for focus border
              input {
                font-size: $font-size-base-dds;
                font-weight: $font-weight-base-dds;
                padding: 0.5rem;
                border: transparent;
                outline: none;
                flex: 1 0 50%;
              }

              button {
                font-size: $font-size-sm-dds;
                background-color: inherit;
                border: none;
                color: $icon-light-color;
                opacity: 0.5;
                cursor: pointer;
                outline: none;
          
                &:last-child {
                  margin-right: 0;
                }
                // height: 25px;
          
                &:focus {
                  box-shadow: 0 0 1px 1px $table-cell-selected-color;
                  border-radius: 4px;
                  opacity: 1;
                }
          
                &:hover {
                  color: dds("700");
                  transition: color 0.2s;
                  opacity: 1;
                }
              }
            }

            .form-panel-wrapper {
              display: flex;
              border-radius: 4px;

              .button-group {
                display: flex;
                flex-direction: column;
                flex: 0 0 15%;

                button {
                  @include button();
                  height: 25px;
                  border: 1px solid #B4B4B4;
                  border-radius: 2px;
                  background: $button-bg-grey;
                  margin-top: 0.5rem;
                  line-height: 1.1;
                  cursor: pointer;
              
                  span {
                    flex: auto;
                    font-size: 14px;
                    font-weight: 500;
                  }
                }
              }
          
              .generic-panel {
                margin-top: 0.5rem;
                flex: 1 0 50%;

                &.route-information-panel {
                  flex: 1 0 auto;
                }
          
                &.enable-margin-left {
                  margin-left: 0.5rem;
                }

                .panel-header {
                  padding: 0;
                }
          
                .panel-body {
                  font-size: 12px;
                  font-weight: 600;
          
                  .container {
                    padding: 0;
                    margin-top: 0.5rem;
          
                    h4 {
                      white-space: nowrap;
                      font-size: 12px;
                      font-weight: 600;
                      margin-bottom: 1rem;
                      margin-top: 0.5rem;
                    }

                    .notes-row {
                      display: flex;

                      label {
                        flex: 0 0 13%;
                        margin: 0 0 0 0.5rem;
                      }

                      .text-area-container {
                        flex: 1 0 auto;
                        display: flex;
                        
                        &.error {
                          textarea {
                            border: 2px solid $otp-danger !important; 
                          }
                        }
                        textarea {
                          flex: 1 0 auto;
                          padding: 0;
                          resize: none;
                          border: 1px solid #B4B4B4;
                          border-radius: 3px;
                          color: $input-color !important;
                        }
                      }
                    }

                    .row {
                      margin: 0 0 0.5rem 0;
                      align-items: center;
                      label {
                        flex: 0 0 13%;
                        margin: 0 0 0 0.5rem;
                      }

                      .ember-power-select-trigger {
                        flex: 1 0 25%;
                      }

                      .ember-text-field-holder {
                        &.error {
                          input {
                            border: 2px solid $otp-danger !important; 
                          }
                        }
                      }

                      .ember-basic-dropdown {
                        flex: 0 0 25%;
                      }

                      .datetimeflatpickr-wrapper {
                        flex: 0 0 25%;

                        input {
                          border: 0;
                          width: 100%;
                        }
                        &.error {
                          input {
                            border: 2px solid $otp-danger !important; 
                          }
                        }
                      }

                      .ember-text-field {
                        input {
                          flex: 0 0 25%;
                          padding: 0;
                          color: $input-color !important;
                        }
                      }


                      .locations-input {
                        flex: 1 0 25%;
                        .edit {
                          flex: 1 1 auto;
                          color: $input-color !important;
                        }

                        &.error {
                          .edit{
                            .ember-power-select-trigger {
                              border: 2px solid $otp-danger !important;
                            }
                          }
                        }

                        .ember-power-select-selected-item {
                          font-size: $font-size-xsm-dds;
                        }
                      }
                    }
          
                  }
                }
              }
            }

            .driver-break-master-table {
              .table-component {
                overflow: inherit;
              }
            }

            .driver-break-master-button{
              display: flex;
              justify-content: flex-end;
              padding: 10px 0px 10px 10px;
              button {
                @include button;
                height: 26px;
                width: 101px;
                border: 1px solid #B4B4B4;
                border-radius: 2px;
                background: $button-bg-grey;
            
                span {
                  flex: auto;
                  font-size: 14px;
                  font-weight: 500;
                }
              }

              .tooltip-running {
                min-width: 98px;
              }
            }
          }
        }
      }
  }
}