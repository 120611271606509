.vehicle-type-view{

  .row {
    margin: 0 0 0.5rem 0;
    align-items: center;
  }

  label {
    margin: 0;
  }

  input[type='checkbox'] {
    vertical-align: middle;
  }

  .generic-panel {
    font-size: 12px;
    font-weight: 500;
    flex: 1 1 auto;

    .col-4 {
      &.error {
        .ember-power-select-trigger{
          border: solid 2px $otp-danger !important;
        }
      }
    }

    .panel-body {
      padding: 0 4rem 0 4rem;
    }
  }
}