.user-profile {
  .secondary-window {
    .secondary-window-modal {

      .secondary-window-content {
        padding: 60px 60px 90px 60px;
      }

      .secondary-window-header {
        font-size: 32px;
        line-height: 40px;
        padding: 0px 0px 30px 0px;
      }

      .secondary-window-body {
        background-color: transparent;

        .profile-container {
          display: flex;
          gap: 20px;
          width: 100%;
          border-radius: 30px;
          background-color: #f8f9fa;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }

        /* Sidebar styling */
        .profile-nav {
          width: 350px;
          padding: 20px;
          flex-shrink: 0;
        }

        .nav-link {
          color: #303E44;
          //background: linear-gradient(269deg, #9CB3B6 0%, #727272 100%);
          margin-bottom: 12px;
          padding: 10px;
          border-radius: 10px;
          box-shadow: 0px 2px 5px rgba(38, 50, 56, 0.50);
          transition: background-color 0.3s ease, color 0.3s ease;
          font-size: 20px;
          font-family: $ui-font;
          font-weight: 600;
          line-height: 40px;
          word-wrap: break-word;
          text-align: center;
        }

        .nav-link.active {
          background: transparent;
          box-shadow: none;
          border-radius: none;
          color: #303E44;
        }


        /* Content area */
        .profile-content {
          width:1100px;
          padding: 20px;
        }
      }
    }
  }
}
