.reoptimize {
  .secondary-window {
    .secondary-window-modal {
      min-width: 30vw;
      height: auto;

      .secondary-window-header {
        height: 33px;
        background-color: $modal-dialog-header-bg;
        color: #303E44;
        background-image: none;
        box-shadow: 0 2px 4px 0 rgba(148,148,148,0.5);
        padding-left: 11px;
        position:relative;
        left:3px
      }

      .secondary-window-body {
        height: auto;
        width: 770px;
        color: #303E44;
        background-color: white;
        padding: 12px;
        .reoptimize-wrapper {
          display: flex;
          justify-content: space-around;
          height: 534px;
        }
      }

      .highlightable {
        color:  #d13730;
      }

      .highlightable-success {
        color: #28a745;
      }

    }


  }
}
