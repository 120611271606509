.places {
    .container {
  padding: 0.5rem;
}

.places-form-panel-wrapper {
  display: flex;
  margin-top: 0.5rem;
}

.errorField {
  input{
      border: solid 2px $error-dds !important;
  }
}

.generic-panel {
  &.address {
    flex: 0 0 49%;
  }

  &.notes {
    margin-left: 0.5rem;
    flex: 0 0 50%;
  }

  .panel-body {
    font-size: 12px;
    font-weight: 500;

    .container {
      padding-top: 0;

      .address-panel {
        label {
          flex: 0 0 32%;
          white-space: nowrap;
          margin-bottom: 1rem;
        }
      }

      .input-group {
        flex-wrap: nowrap;

        .ember-text-field {
          flex: 0 0 66%;
          height: 25px;
        }
      }

      .errorField{

        .ember-basic-dropdown-trigger {
          border: solid 2px $error-dds !important;
        }
      }

      .ember-power-select-trigger {
        height: 25px;


      }

      .input-group{
        .limit-address-width{
          width:0em;
        }
      }
    }
  }
}

.input-group-geo {
  &.ember-text-field {
    flex: 0 0 32% !important;
    margin-right: 0.5rem !important;
  }
}

.notes-panel {
  .ember-text-area {
  width: 98%;
  height: 160px;
  }
}

.address-panel {
  .input-group-notes .ember-text-field {
    flex: 0 0 80%;
    height: 25px;
  }

  .locations-input {
    display: block;
    width: 100%;
    padding: 0 7px 0 0px;
}
}

}
