.route-widget {
  .column-widget-button{
    font-size: unset !important;
    opacity: 0.6 !important;
    padding: 0px !important;

    &:hover {
      opacity: 1 !important;
      color: none !important;
    }
  }
  .minimize-button,
  .download-button {
    width: 30px;
    height: 26px;
    //background: linear-gradient(269deg, #9CB3B6 0%, #727272 100%);
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.20);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .download-btn-icon {
    width: 30px;
    height: 26px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.20);
  }

  .hidden {
    display: none;
  }
}
