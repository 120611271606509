$generic-panel-width: calc(100%);

div#speed-region {
  height: 100%;
  width: 100%;
}
.side-drawer-modal.show-right-panel {
  right: 0% !important;
}

.speed-region-groups {
  label {
    margin: 0;
  }
  .container {
    padding: 0.5rem;
    max-width: $generic-panel-width;
    
    .speed-region-group-table {
      height: 177px;
    }
  }

  .form-panel-wrapper {
    display: flex;
    margin-top: 0.5rem;
    // height: 37rem;
  }

  .left-form-panel-wrapper {
    display: flex;
    flex-direction: column;
    flex: 0 0 $generic-panel-width;
  }

  .error {
    input,
    .ember-power-select-trigger {
      border: solid 2px $error-dds !important;
    }
  }

  .generic-panel {
    position: relative;
    flex: 0 0 $generic-panel-width;

    &:first-child {
      margin-right: 0.5rem;
    }

    &.trip-count {
      width: 100%;
      margin-top: 0.5rem;

      .trip-count-form-container {
        display: flex;
        flex-direction: row;
        height: 30px;
        .section {
          margin: 10px;

          &:first-child {
            min-width: 94px;
          }

          label {
            font-weight: 600;
            font-size: $font-size-xsm-dds;
          }
        }

        .datetimeflatpickr-wrapper i {
          right: 16%;
        }
      }

      .spacer-0 {
        min-width: 1rem; // 16px
      }

      .table-header {
        font-size: 12px;
        font-weight: 600;
        vertical-align: bottom;
        width: 100%;
      }

      .input-cell {
        width: 110px;
      }

      .days-of-service-table {
        white-space: nowrap;

        input {
          border-radius: 2px;
          font-size: $font-size-xsm-dds;
          font-weight: 600;
          line-height: 1rem;
          height: 25px;
          padding: 3px 7px 0;
          display: inline-block;
        }

        .input-cell {
          width: 110px;
        }
      }
    }

    &.cost-info {
      width: 100%;
    }

    .ember-power-select-trigger {
      max-width: 50%;
      font-weight: 100 !important;
      line-height: 20px;
    }

    .panel-body {
      font-size: 12px;
      font-weight: 600;

      .container {
        padding-top: 0;
        display: flex;

        .speed-region-map {
          border: solid 1px grey;
          width: 78%;
        }

        .main-information-panel {
          align-items: baseline;
          flex-wrap: nowrap;
          flex-direction: column;
          width: 340px;
          margin-right: 10px;

          .speed-factor-tabs {
            margin-top: 8px;

            * {
              box-sizing: border-box;
              font-size: 11px;
            }
            body {
              font-family: "Open Sans";
              background: #2c3e50;
              color: #ecf0f1;
              line-height: 1.618em;
            }
            .wrapper {
              max-width: 50rem;
              width: 100%;
              margin: 0 auto;
            }
            .tabs {
              position: relative;
              background-color: #f5f5f7;
              height: 15.75rem;
              border: 1px solid lightgray;
            }
            .tabs::before,
            .tabs::after {
              content: "";
              display: table;
            }
            .tabs::after {
              clear: both;
            }
            .tab {
              float: left;
            }
            .tab-switch {
              display: none;
            }
            .tab-label {
              position: relative;
              display: block;
              line-height: 2.75em;
              height: 3em;
              padding: 0 13px;
              color: darkgray;
              cursor: pointer;
              top: 0;
              transition: all 0.25s;
            }
            .tab-label:hover {
              top: -0.25rem;
              transition: top 0.25s;
            }
            .tab-content {
              height: 13.6rem;
              position: absolute;
              z-index: 1;
              top: 2.75em;
              left: 0;
              padding: 0.5rem;
              background: #fff;
              color: #2c3e50;
              opacity: 0;
              transition: all 0.35s;
              width: 100%;
              overflow-y: scroll;

              .tab-content-row {
                display: flex;
                background-color: #fff !important;
                margin: 10px 5px 0 10px;

                .speed-factor-input {
                  width: 57px;
                  
                  &:disabled {
                    background-color: #f5f5f7;
                  }
                }

                span.time-period-col {
                  width: 214px;
                  margin-bottom: 1px;
                }

                span.change-by-col {
                  width: 63px;
                  margin-bottom: 1px;
                }    

                .speed-factor-label {
                  font-weight: bolder;
                }            
              }
            }
            .tab-switch:checked + .tab-label {
              background: #fff;
              color: #2c3e50;
              border-bottom: 0;
              border: 1px solid lightgray;
              transition: all 0.35s;
              z-index: 1;
              top: -0.0625rem;
            }
            .tab-switch:checked + label + .tab-content {
              z-index: 2;
              opacity: 1;
              transition: all 0.35s;
            }
          }
          .speed-region-table {
            margin-top: 10px;
            margin-bottom: 8px;
            height: 150px;
          }

          .panel-header {
            height: 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .panel-header-buttons {
              flex-grow: 0;
              flex-shrink: 0;
              line-height: 1.1;
              cursor: default;
              .btn {
                padding: 0 5px;
                font-size: 12px;
                margin-left: 7px;
                i {
                  margin-right: 0;
                }
              }
            }
          }

          .message-group {
            display: flex;
            justify-content: flex-end;

            .message-text-area {
              width: 242px;
              margin-left: 16px;
              margin-bottom: 4px;
            }
          }
          .blockout-search-address {
            margin-top: 11px;
            // border: solid 1px lightgrey;
            // border-radius: 0.25rem;

            button.btn.icon-btn.btn-add-address {
              color: dimgrey;
              border: solid 1px #acb8bd;
              background-color: transparent;
              border-radius: 0.25rem;
              height: 22px;
              width: 21px;
              margin-right: 5px;
            }
            .cell.button {
              cursor: pointer;
              border: solid 1px #acb8bd;
              color: black;
              background-color: transparent;
              border-radius: 0.25rem;
              width: 21px;
            }

            .locations-input {
              // flex: 0 0 71%;

              button.cell.button {
                margin-right: 4px;
              }
              .cell.edit {
                max-width: 100% !important;

                .ember-basic-dropdown-trigger {
                  max-width: 100%;
                  height: 25px;
                  width: 282px;
                }
              }
            }
          }

          .blockout-addresses-table {
            height: 143px;
          }

          .input-group {
            align-items: center;
            flex-wrap: nowrap;
            height: 28px;
            display: flex;
            justify-content: flex-end;
  
            .ember-text-field {
              flex: 0 0 71%;
              height: 25px;
            }
            
            .region-name-label {
              margin-right: 10px;
            }

            .blockout-time-input {
              max-width: 70px;
            }
        
            .blockout-dateTime-input {
              max-width: 167px;
              margin-right: 4px;
            }
          }

          label {
            flex: 0 0 15%;
            white-space: nowrap;
          }

          .ember-power-select-trigger {
            ul {
              li {
                display: none;
              }
            }
            .ember-power-select-trigger-multiple-input {
              min-width: 100%;
              border: none;
              padding: 0;
            }
          }

          .blockout-recurrence-group {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .first-row {
              margin-top: 11px;
              display: flex;
              justify-content: flex-end;

              .recurrence-label {
                margin-right: 30px;
              }
            
              .continuous-label {
                width: 131px;
              }
            }

            .second-row {
              margin-bottom: 10px;
              display: flex;
              justify-content: flex-end;

              .recurs-label {
                width: 131px;
              }
            }
          }
        }
      }
    }
  }

  .disable-btn {
    pointer-events: none;
    opacity: 0.6;
  }
}
.ember-modal-dialog .system-config-wrapper input {
  &.input-add-zone:focus {
    border: solid 2px $dds-800;
  }
  &.error {
    border: solid 2px $error-dds;
  }
}

.draw-control-disabled {
  filter: contrast(30%) brightness(140%);
  pointer-events:none;
}

.speed-region-disabled {
  pointer-events:none;
}

.speed_region_map_error {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255,255,255,0.8);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 5px;
  color: red;

  &.disabled {
    display: none;
  }
}

.leaflet-tooltip-pane {
  display: flex !important;

  .polygon-labels {
    background-color: transparent;
    font-weight: 300;
    color: blue;
    border: transparent;
    box-shadow: none;
    font-size: 14px;
  }

  .marker-labels {
    height: 22px;
    display: flex;
    align-items: center;
  }
}
