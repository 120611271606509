#require-new-password {
  margin: 150px auto;
  width: 50%;
  max-width: 550px;
  font-family: $ui-font;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;  // Center horizontally

  h1 {
    margin-top: 10px;
    font-weight: 700;
  }

  .p-4 {
    border-radius: 20px; /* Apply the same border-radius to inner container */
  }

  .content {
    padding-top: 1rem;
    border-top: 1px solid #b5b5b5;
    text-align: right;

    label {
      width: 100%;
      text-align: left;
      margin-bottom: 120px;
    }

    .btn {
      line-height: 1.7;
      padding: .5rem 1rem;
      font-size: 15px;
      background-color: $light-dds;
      border: 1px solid $button-outline-primary;
      margin-left: .5rem;
      color: $button-link-color;
      border-radius: 10px;

      &:disabled {
        color: $button-link-disabled-color;
        border: 1px solid $button-link-disabled-color;
        &:hover {
          cursor: not-allowed;
          background-color: $light-dds;
          border: 1px solid $button-link-disabled-color;
          color: $button-link-disabled-color;
        }
      }

      &:hover {
        background: $dds-primary-gradient;
        color: $button-link-alt-color;
        border: 1px solid $dds-primary-gradient;
      }
    }
  }
}
