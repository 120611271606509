.ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center {
  .capacity-modal-styles-overlay {
    padding: 0;
    border-radius: 1;
    z-index: zindex(action-modal);
    outline: none;
    &.ember-modal-overlay.translucent {
      background-color: rgba(38, 50, 56, 0.2);
    }

    .ember-modal-dialog {
      background-color: transparent;
      box-shadow: none;
      z-index: zindex(action-modal);

      .capacity-modal {
        background: $light-dds;
        border-radius: 10px;
        box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.4);
        max-width: 600px;
        &.vehicle-search {
          width: 600px;
        }

        .capacity-modal-header {
          height: 40px;
          display: flex;
          align-content: baseline;
          justify-content: space-between;
          background: $background-lighter;
          font-size: $font-size-base-dds;
          border-radius: 10px 10px 0 0;
          padding: 0.5rem;
          @include box-shadow(0, 2px, 4px, rgba(148, 148, 148, 0.5));

          .table-component-content {
            z-index: calc(zindex(action-modal) + 1);
          }

          h5 {
            font-size: $font-size-base-dds;
            line-height: 22px;
          }

          .capacity-modal-buttons {
            display: flex;
            align-items: center;
            padding: 0 0.5rem 0 0.5rem;

            i {
              @include icon($icon-light-color);
              font-size: 1em;
              margin-left: 0.5rem;
              opacity: 1;
            }
          }
        }

        .capacity-modal-body {
          padding: 1rem;
          font-size: small;
          font-weight: 100;

          .col-4 {
            &.error {
              .ember-basic-dropdown-trigger {
                border: 2px solid $otp-danger !important;
              }
            }
          }

          .capacity-modal-button {
            display: flex;
            justify-content: flex-end;
            padding: 10px 0px 10px 10px;

            button.cancel {
              margin-right: 1rem;
              height: 1.6rem;
            }
            button {
              @include button;
              height: 26px;
              width: 101px;
              border: 1px solid #b4b4b4;
              border-radius: 2px;
              background: $button-bg-grey;

              span {
                flex: auto;
                font-size: 14px;
                font-weight: 500;
              }
            }

            .tooltip-running {
              min-width: 98px;
            }
          }
        }
      }
    }
  }
}
