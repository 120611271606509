.maintenance {
  .table-component .table-component-body {
    flex-basis: 210px;
  }

  .col {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .row {
    margin: 0;
  }

  .generic-panel {

    &.un-scheduled-panel {
      .col-6 {
        padding: 0 3rem 0 0;
      }
    }

    .panel-body {
      font-size: 12px;
      font-weight: 500;
      padding: 0 2rem 0 2rem;

      label {
        margin: 0;
      }

      .row {
        margin-bottom: 0.5rem;
        align-items: center;
      }
    }
  }
}
