.vehicle-table {

  .table-component-body {
    height: 100%;
  }

  .table-component-button {
    background: linear-gradient(269deg, #9CB3B6 0%, #727272 100%);
    border-radius: 6px;
    border: none;
    display: flex;
    justify-content: center; /* Align items to the start */
    align-items: center; /* Vertically centers items */
    gap: 10px; // Control gap between the text and image

    &:focus {
      .icon {
        color: #303E44;
      }
    }

    &:hover {
      .icon {
        color: white;
        background: $dds-primary-gradient;
        transition: color 0.2s;
      }
    }

    .icon {
      color: white;
    }
  }

}
