.ember-modal-dialog-target-attachment-right {
  position: absolute;
  right: 0;
  width: 340px;
  min-width: 200px;
  background-color: white;
  font-family: "Avenir Next";

  .ember-modal-dialog-target-attachment-right {
    .blockout-alternative-addresses {
      width: 340px;

      .g-side-drawer-body {
        background-color: white;

        .alternative-address-message {
          background-color: #f5f5f7;
          margin: 8px 8px 8px 8px;
          border-radius: 5px;

          .alternative-address-inside-message {
            margin: 6px 6px 6px 6px;
            font-size: medium;
            font-family: "Avenir Next";

            pre.alt-message-text-area {
              white-space: pre-wrap;
              font-family: "Avenir Next";
            }
          }
        }

        .alternative-addresses-options {
          background-color: #f5f5f7;
          margin: 1px 8px 8px 8px;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          height: 301px;
          overflow-y: scroll;
          justify-content: center;
          align-items: baseline;
          font-size: smaller;
          white-space: nowrap;
          font-weight: 500;
          font-family: "Avenir Next";

          
          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #c1c1c1;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #919090;
          }

          label.alt-address-label {
            margin-left: 3px;
            display: inline-block;
            vertical-align: middle;
          }

          .address-row {  
            display: flex;
            height: 26px;
            align-items: center;
            width: 318px;

            &:hover {
              background-color: dds("600");
              cursor: pointer;
            }

            label {
              margin: 0px 0px 0px 10px;
            }

            &.selected {
              background-color: dds("600");
            }
          }
        }

        .alt-address-buttons {
          display: flex;
          justify-content: center;
          margin-top: 30px;
          gap: 32px;
          font-family: "Avenir Next";

          button {
            text-decoration: none;
            text-align: center;
            text-decoration: none;
            color: black;
            vertical-align: middle;
            height: 26px;
            width: 75px;
            border: 1px solid #B4B4B4;
            border-radius: 5px;
            background: linear-gradient(180deg, #f9f9f9 0%, #d1d1d1 100%);
            margin-left: 20px;
            line-height: 1.1;
            cursor: pointer;

            &:hover {
              opacity: 0.75;
            }
          }
        }
      }
    }
  }
}
