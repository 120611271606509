#login-template {
  margin: 150px auto;
  font-family: $ui-font;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0rem;
  justify-content: space-evenly;
  gap: 40px;
  overflow-x: hidden;
  max-width: 100vw;

  .logo-container {
    width: 900px;
  }

  .logo-container img.logo {
    width: 100%;
    max-width: 100%;
    height: auto;
    padding: 10px;
  }

  .form-container {
    width: 500px;
  }

  h3 {
   font-family: $ui-font;
  }

  .p-4 {
    border-radius: 20px; /* Apply the same border-radius to inner container */
  }

  form {
    padding-top: 1rem;
    text-align: right;

    .dropdown {
      text-align: center;
      margin-left: 8px;
    }

    label {
      width: 100%;
      text-align: left;

      input[type=text],
      input[type=email],
      input[type=password] {
        display: block;
        width: 100%;
        padding: 3px 7px;
        line-height: 1.5em;
        color: $input-color;
        background-color: $input-bg;
        background-clip: padding-box;
        border: $input-border-width solid $input-border-color;
        border-radius: 5px;
        outline: none;
      }

      input[disabled] {
        background: #EFEFEF;
      }
    }

    .btn {
      line-height: 1.7;
      padding: .5rem 1rem;
      font-size: 15px;
      background-color: $light-dds;
      border: 1px solid $button-outline-primary;
      margin-left: .5rem;
      color: $button-link-color;
      border-radius: 10px;
      margin-top: 15px;

      &:disabled {
        color: $button-link-disabled-color;
        border: 1px solid $button-link-disabled-color;
        &:hover {
          cursor: not-allowed;
          background-color: $light-dds;
          border: 1px solid $button-link-disabled-color;
          color: $button-link-disabled-color;
        }
      }

      &:hover {
        background: $dds-primary-gradient;
        color: $button-link-alt-color;
        border: 1px solid $dds-primary-gradient;
      }
    }

    .errors {
      color: $light-dds;
      background-color: #ec7272;
      border-radius: 3px;
      margin: 1em;
      padding: 5px 3px;
      list-style: none;
      text-align: center;
    }
  }
   // Media query for smaller screens
   @media (max-width: 768px) {
    flex-direction: column;

    .logo-container {
      width: 80%;
    }

    .form-container {
      width: 80%;
    }
  }
}
