.vehicle-capacity-type-view{

  .row {
    margin: 0 0 0.5rem 0;
  }

  input[type='checkbox'] {
    vertical-align: middle;
  }

  .generic-panel {
    font-size: 12px;
    font-weight: 500;
    flex: 1 1 auto;

    .panel-body {
      padding: 0 4rem 0 4rem;

      .col {
        input {
          width: 80%;
        }
        label {
          width: 80%;
        }
      }
    }
  }
}