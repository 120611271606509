.vehicle-wrapper {
.vehicle-wrapper-header {
  display: flex;
  align-items: center;
  label {
      font-size: 12px;
      margin: 5px;
      width: 72px;
      line-height: 18px;
      font-weight: 500;
      font-family: $font-family-dds-bold;
  }
  .form-group {
    display: flex; /* Apply flexbox to form group to control layout */
    align-items: center;
    width: 100%; /* Ensure the form group takes full width */

    .form-control {
        flex-grow: 1; /* Allows form control to take up the remaining space */
        margin: 9px 1px;
        font-size: 12px;
        font-weight: 500;
        font-family: $font-family-dds-bold;
    }
    .form-control:disabled,
    .form-control[readonly] {
        background-color: #e9ecef;
        opacity: 1;
        cursor: default;
    }
  }
  .name-fields {
    .form-control {
        width: 100px;
    }
  }

  .ember-power-select-trigger {
    flex-grow: 1;
    height: 25px;
    margin: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    font-family: $font-family-dds-bold;
    border-radius: 4px;

    &[aria-disabled=true] {
      background: #e9ecef !important;
      opacity: 1;
      outline: none;
      cursor: default;
    }
  }

  .btn {
    background: linear-gradient(269deg, #9CB3B6 0%, #727272 100%);
    border-radius: 4px;
    border: none;
    cursor: pointer;
    outline: none;
    font-style: $ui-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    color: $icon-light-color;

    &:hover {
      color: $icon-light-color;
      background: $dds-primary-gradient;
      transition: color 0.2s;
      opacity: 1;

      .icon {
        color: white;
        background: $dds-primary-gradient;
        transition: color 0.2s;
      }
    }

    .icon {
      color: white;
      margin-right: 5px;
    }
  }


  .btn-focused {
    color:  #303E44;
    background: white;
    transition: color 0.2s;
    opacity: 1;

    .icon {
      color: #303E44;
    }
  }


  .vehicle-detail {
    display: flex;
    justify-content: space-between;
  }

  .error {
    input,
    .ember-power-select-trigger {
      border: solid 2px $otp-danger !important;
    }
  }
 }
}
